import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import EN from "../../config/en";

export default function Logo({ showTitle = true }) {
  const [show, setShow] = useState(true);

  const translate = useSelector((state) => state.globalState.translate);

  useEffect(() => {
    if (showTitle) {
      setTimeout(() => {
        setShow(showTitle);
      }, 200);
    } else {
      setShow(showTitle);
    }
  }, [showTitle]);

  return (
    <div
      style={{
        width: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <svg
        width={show ? 80 : 43}
        height={show ? 40 : 24}
        viewBox="0 0 44 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.5 0H27.5V6.5H0.5V0Z" fill="#7367C1" />
        <rect x="30" width="13.5" height="6.5" fill="#E257AF" />
        <rect x="30" y="17.5" width="13.5" height="6.5" fill="#FFB05B" />
        <rect x="16.5" y="8.5" width="27" height="6.5" fill="#4FC2E6" />
        <rect x="0.5" y="8.5" width="13.5" height="6.5" fill="#57BE8F" />
        <path d="M0.5 17.5H27.5V24H0.5V17.5Z" fill="#F14949" />
      </svg> */}
      <svg
        width="127"
        height="24"
        viewBox="0 0 127 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0H27V6.5H0V0Z" fill="#7367C1" />
        <rect x="29.5" width="13.5" height="6.5" fill="#E257AF" />
        <rect x="29.5" y="17.5" width="13.5" height="6.5" fill="#FFB05B" />
        <rect x="16" y="8.5" width="27" height="6.5" fill="#4FC2E6" />
        <rect y="8.5" width="13.5" height="6.5" fill="#57BE8F" />
        <path d="M0 17.5H27V24H0V17.5Z" fill="#F14949" />
        <path
          d="M81.0367 23.4625L75.1037 14.9565C78.6184 14.0079 80.4241 11.668 80.4241 8.06324C80.4241 3.60474 76.9416 0.569169 71.6212 0.569169H52.5V23.4625H58.1751V15.3676H68.9126L74.6522 23.4625H81.0367ZM71.0086 10.9723H58.1751V4.96443H70.9763C73.3947 4.96443 74.8135 6.16601 74.8135 7.90514C74.8135 9.80237 73.4269 10.9723 71.0086 10.9723Z"
          fill="black"
        />
        <path d="M92.1204 23.4625V0.569169H86.4453V23.4625H92.1204Z" fill="black" />
        <path
          d="M122.437 7.01976L125.662 3.35178C122.244 1.13834 117.633 0 111.925 0C103.477 0 98.8984 2.84585 98.8984 7.33597C98.8984 11.9209 103.38 13.6917 112.473 14.0711C117.439 14.2925 120.47 14.2925 120.47 16.3162C120.47 18.1818 117.826 19.5099 113.118 19.5099C108.765 19.5099 104.799 18.3715 100.801 15.9684L97.7053 19.6996C102.187 22.7352 107.024 24 112.86 24C121.728 24 126.5 21.0593 126.5 16.0949C126.5 11.6047 122.985 9.86561 115.666 9.20158C112.054 8.88537 109.056 9.04348 106.831 8.50593C105.476 8.18972 104.799 7.65217 104.799 6.86166C104.799 5.37549 107.314 4.4585 112.183 4.4585C115.827 4.4585 119.148 5.28063 122.437 7.01976Z"
          fill="black"
        />
      </svg>
      {/* <h1
        style={{
          fontSize: 20,
          display: show ? "block" : "none",
        }}
        className="app-name"
      >
        {translate["RIS"] || EN["RIS"]}
      </h1> */}
    </div>
  );
}
