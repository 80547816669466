import React, { useState } from "react";

import REQUESTS from "../../../server/requests";

import TableHeadComponent from "../../elements/TableHeadComponent";
import CsvLinkComponent from "../../elements/CsvLinkComponent";

export default function CountryTableHead() {
    const [clickedCsv, setClickedCsv] = useState(false);
    const [dataList, setDataList] = useState([]);

    const onClicCsv = (event, done) => {
        if (!clickedCsv) {
            setClickedCsv(true);

            function callback(data) {
                const newList = data.map((list) => {
                    return {
                        Name: list.name,
                        Data: list.createdAt,
                    };
                });

                setDataList(newList);

                setClickedCsv(false);
            }

            function errorCallback() {
                setClickedCsv(false);
            }

            REQUESTS.COUNTRY.GET({}, callback, errorCallback);
        }
    };
    return (
        <TableHeadComponent>
            <CsvLinkComponent filename="Country-List.csv" data={dataList} onClick={onClicCsv} />
        </TableHeadComponent>
    );
}
