import React, { useContext, useState } from "react";
import REQUESTS from "../../../server/requests";

import HotelStaffContext from "./HotelStaffContext";

import ICONS from "../../../config/icons";
import IconButton from "../../elements/IconButton";
import TableHeadComponent from "../../elements/TableHeadComponent";
import CsvLinkComponent from "../../elements/CsvLinkComponent";

import ViewSettingsDrawer from "./ViewSettingsDrawer";

export default function TableHead() {
    const { setVisibleViewSettingsDrawer } = useContext(HotelStaffContext);

    const [clickedCsv, setClickedCsv] = useState(false);
    const [dataList, setDataList] = useState([]);

    const onClicCsv = (event, done) => {
        if (!clickedCsv) {
            setClickedCsv(true);

            function callback(data) {
                const newList = data.map((list) => {
                    let roles;
        

                    if(list.employers_roles){
                        roles = list.employers_roles.map((item) => item.role.name);
                        {roles.join()}
                    }else {
                        roles = "-"
                    }
                    return {
                        Image: list.image,
                        Name: list.name,
                        Surname: list.surname,
                        Email: list.email,
                        Roles: roles,
                        Data: list.createdAt,
                    };
                });

                setDataList(newList);

                setClickedCsv(false);
            }

            function errorCallback() {
                setClickedCsv(false);
            }

            REQUESTS.EMPLOYERS.GET({}, callback, errorCallback);
        }
    };
    return (
        <>
            <TableHeadComponent>
                <CsvLinkComponent
                    filename="Users-List.csv"
                    data={dataList}
                    onClick={onClicCsv}
                />
                <IconButton
                    icon={ICONS.SETTINGS}
                    onClick={() => setVisibleViewSettingsDrawer(true)}
                />
            </TableHeadComponent>

            <ViewSettingsDrawer />
        </>
    );
}
