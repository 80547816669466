export const API_HOST = "http://api.ristv.com/";

// ? "http://" + window.location.hostname + ":5030/"
// : "http://hosting2.inorain.com:5030/";

const URLS = {
  // a
  AIRPORT: `${API_HOST}airport`,
  APPLICATION: `${API_HOST}tv_applications`,
  // b
  // c
  CATEGORIES: `${API_HOST}categories`,

  CHECK_OUT: `${API_HOST}rooms/checkout`,

  CONFIRM_PASSWORD: `${API_HOST}employers/configmPasswordChenge`,
  COUNTRY: `${API_HOST}country`,
  CURRENCY: `${API_HOST}currency`,
  CONVERSATIONS: `${API_HOST}conversations`,
  CHAT: `${API_HOST}chat`,
  CHAT_QUICK_MESSAGES: `${API_HOST}chat/quick_messages`,
  // d
  // e
  EXIT: `${API_HOST}tv_app_exit_config`,
  EMPLOYERS: `${API_HOST}employers`,
  EDIT_PASSWORD: `${API_HOST}employers/editPassword`,
  EPG: `${API_HOST}epg`,
  EPG_CHANNELS: `${API_HOST}epg_channels`,

  // f
  FILE: `${API_HOST}file`,
  FEEDBACK_QUESTIONS: `${API_HOST}feedback_questions`,
  FEEDBACK_HISTORY: `${API_HOST}room/feedback_questions`,
  // g
  GET_PROFILE: `${API_HOST}employere/getProfile`,
  GENRES: `${API_HOST}genres`,

  GUESTS: `${API_HOST}guests`,
  GUESTS_GROUPS: `${API_HOST}guests_groups`,

  GUI: `${API_HOST}gui`,

  GUEST_APPLICATIONS: `${API_HOST}guests/application_form`,

  // h
  HOTEL_INFORMATION: `${API_HOST}hotel_information`,
  HOTEL_INFORMATION_GALERY: `${API_HOST}hotel_information_galery`,
  HELP_INFO: `${API_HOST}help_screen`,

  // i
  INFO_CENTER_CATEGORIES: `${API_HOST}informations_categories`,
  INFORMATION: `${API_HOST}information`,
  INFORMATION_GALLERY: `${API_HOST}information_galery`,
  IMPORT_CSV: `${API_HOST}room_devices/import_csv`,
  // j
  // k
  // l
  LOGIN: `${API_HOST}employers/login`,
  LANGUAGES: `${API_HOST}languages`,

  LOCAL_INFORMATION: `${API_HOST}local_information`,
  LOCAL_INFORMATION_GALERY: `${API_HOST}local_information_galery`,

  USEFUL_INFORMATION: `${API_HOST}useful_info`,
  USEFUL_INFORMATION_GALERY: `${API_HOST}useful_info_galery`,

  // m
  // n
  NEWS: `${API_HOST}news`,
  NEWS_CATEGORIES: `${API_HOST}news_categories`,
  // o
  ORGANIZATION: `${API_HOST}organization`,
  ORDER: `${API_HOST}order`,
  ORDER_PRODUCTS: `${API_HOST}order`,
  ORDER_SERVICES: `${API_HOST}servic_orders`,
  ONLINE_DEVICES: `${API_HOST}online/devices`,

  // p
  PACKAGES: `${API_HOST}packages`,
  PRODUCTS: `${API_HOST}products`,
  // PRODUCT_CATEGORIES: `${API_HOST}product_categories`,
  ECOMMERCIAL_CATEGORIES: `${API_HOST}e_commercial_catagories`,
  PMS_OPERA: `${API_HOST}opera`,

  // q
  QUICK_MESSAGES: `${API_HOST}quick_messages`,

  // r
  ROLES: `${API_HOST}roles`,
  ROOMS: `${API_HOST}rooms`,
  ROOM_DEVICES: `${API_HOST}room_devices`,

  ROOM_GROUPS: `${API_HOST}room_groups`,
  RESET_PASSWORD: `${API_HOST}employers/resetPassword`,

  // s
  SERVICES: `${API_HOST}services`,
  STATISTICS_DASHBOARD: `${API_HOST}statistics`,
  STATISTICS_GUESTS: `${API_HOST}statistics_guests`,
  STATISTICS_ORDER: `${API_HOST}statistics/order`,
  STATISTICS_GUESTS_COUNTRY: `${API_HOST}statistics_guests_country`,
  STATISTICS_FEEDBACK_QUESTIONS: `${API_HOST}feedback_questions/statistics`,
  SCREEN_MIRRORING: `${API_HOST}cast`,
  // HELP_INFO: `${API_HOST}help_screen`,

  // t
  TV_MENU: `${API_HOST}tv_menu`,
  TV_MENU_CHANGE: `${API_HOST}tv_menu/change`,
  TV_MENU_POSITION: `${API_HOST}tv_menu/update_position`,

  TV_APP_CONFIG: `${API_HOST}tv_app_config`,
  TV_CHANNELS: `${API_HOST}tv_channels`,
  TV_CHANNELS_SETTINGS: `${API_HOST}tv_channels/settings`,
  TV_CHANNELS_CATEGORIES: `${API_HOST}tv_categori`,
  TV_SHOW_SEASONS: `${API_HOST}tv_show_seasons`,
  TV_SHOW_EPISODES: `${API_HOST}tv_show_episodes`,
  TRANSLATIONS: `${API_HOST}translations`,
  // u
  UPLOAD: `${API_HOST}upload`,
  UPLOAD_STATUS: `${API_HOST}upload-status`,

  // v
  VOD: `${API_HOST}vod`,

  // w
  // x
  // y
  // z
};

export default URLS;
