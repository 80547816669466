import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Input } from "antd";

import ServiceContext from "./ServiceContext";

import REQUESTS from "../../../../server/requests";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";
import { dateFormat } from "../../../../config/formats";
import confirmModal from "../../../../config/confirmModal";

import ImageComponent from "../../../elements/ImageComponent";
import TableButtons from "../../../elements/TableButtons";
import LongText from "../../../elements/LongText";
import Page from "../../../elements/Page";
import Check from "../../../elements/Check";

import TableHead from "./TableHead";
import ServiceDrawer from "./ServiceDrawer";
import ServicesHeader from "./ServicesHeader";

import "./_services.scss";
import Currency from "../../../elements/Currency";

export default function ServicesPage() {
  const translate = useSelector((state) => state.globalState.translate);

  const [loading, setLoading] = useState(false);

  const [servicesList, setServicesList] = useState([]);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [nameSearch, setNameSearch] = useState(null);
  const [categorySearch, setCategorySearch] = useState("");
  const [employerSearch, setEmployerSearch] = useState("");

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentService, setCurrentService] = useState(null);

  const [visibleFilterDrawer, setVisibleFilterDrawer] = useState(false);
  const [visibleServiceDrawer, setVisibleServiceDrawer] = useState(false);
  const [visibleViewSettingsDrawer, setVisibleViewSettingsDrawer] =
    useState(false);

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const [selectedEmployerId, setSelectedEmployerId] = useState(null);

  const [selectedPublishedStatus, setSelectedPublishedStatus] = useState(null);

  const [viewSettingsValues, setViewSettingsValues] = useState({
    id: true,
    image: true,
    name: true,
    price: true,
    employer: true,
    e_commercial_catagory: true,
    description: true,
    attach_slider: true,
    createdAt: true,
    action: true
  });

  useEffect(() => {
    if (!visibleServiceDrawer) {
      setCurrentService(null);
    }
  }, [visibleServiceDrawer]);

  useEffect(() => {
    getServicesList();
  }, [
    currentPage,
    limit,
    sort,
    selectedCategoryId,
    selectedEmployerId,
    selectedPublishedStatus
  ]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getServicesList();
    }, 1000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [nameSearch]);

  useEffect(() => {
    let timeout = null;
    if (categorySearch[0] || employerSearch[0]) {
      if (categorySearch[0]?.length > 2 || employerSearch[0]?.length > 2) {
        timeout = setTimeout(() => {
          getServicesList();
        }, 1000);
      }
    } else {
      getServicesList();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [categorySearch, employerSearch]);

  useEffect(() => {
    configureTableColumns();
  }, [viewSettingsValues, currentPage]);

  useEffect(() => {
    let E_COMMERCE_SERVICES_COLUMNS = localStorage.getItem(
      "E_COMMERCE_SERVICES_COLUMNS"
    );

    if (E_COMMERCE_SERVICES_COLUMNS) {
      E_COMMERCE_SERVICES_COLUMNS = JSON.parse(E_COMMERCE_SERVICES_COLUMNS);
      setViewSettingsValues(E_COMMERCE_SERVICES_COLUMNS);
    }
  }, []);

  const [columns, setColumns] = useState([]);

  const configureTableColumns = () => {
    const newColumns = [];

    newColumns.push({
      title: "#",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text, record, index) => (currentPage - 1) * limit + 1 + index
    });

    if (viewSettingsValues.image) {
      newColumns.push({
        title: "",
        dataIndex: "image",
        key: "image",
        render: (text, record, index) => (
          <ImageComponent src={record.image} className="tableImage" />
        )
      });
    }

    if (viewSettingsValues.name) {
      newColumns.push({
        title: translate["Name"] || EN["Name"],
        dataIndex: "name",
        key: "name",
        width: 130,
        sorter: true,

        render: (text, record, index) => {
          let title = "";

          const translations = record?.name_translations;

          for (let i = 0; i < translations.length; i++) {
            if (translations[i].is_default) {
              title = translations[i].text;
              break;
            }

            if (translations[i].text) title = translations[i].text;
          }

          return <LongText width={100}>{title || "N/A"}</LongText>;
        },

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },

        onFilter: (value, record) => {
          return record.name.toLowerCase().includes(value.toLowerCase());
        }
      });
    }

    if (viewSettingsValues.employer) {
      newColumns.push({
        title: translate["User"] || EN["User"],
        dataIndex: "employer",
        key: "employer",
        width: 130,
        render: (text, record, index) => (
          <p className="txt-tb-l">{record.employer?.name || "-"}</p>
        ),
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={employerSearch[0]}
              onChange={(e) => {
                setEmployerSearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },

        onFilter: (value, record) => {
          return record.employer?.name
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });
    }

    if (viewSettingsValues.e_commercial_catagory) {
      newColumns.push({
        title: translate["Category"] || EN["Category"],
        dataIndex: "e_commercial_catagory",
        key: "e_commercial_catagory",
        width: 170,
        render: (text, record, index) => {
          return (
            <p className="txt-tb-l">
              {record.e_commercial_catagory
                ? record.e_commercial_catagory.name
                : "-"}
            </p>
          );
        },
        filterDropdown: ({ confirm }) => {
          return (
            <Input
              allowClear
              autoFocus
              placeholder="Type text here"
              value={categorySearch[0]}
              onChange={(e) => {
                setCategorySearch(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          );
        },
        filterIcon: () => {
          return <>{ICONS.SEARCHOUTLINED}</>;
        },

        onFilter: (value, record) => {
          return record.e_commercial_catagory?.name
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });
    }

    if (viewSettingsValues.description) {
      newColumns.push({
        title: translate["Description"] || EN["Description"],
        dataIndex: "description",
        key: "description",
        sorter: true,
        render: (text, record, index) => {
          let title = "";

          const translations = record?.description_translations;
          for (let i = 0; i < translations.length; i++) {
            if (translations[i].is_default) {
              title = translations[i].text;
              break;
            }

            if (translations[i].text) title = translations[i].text;
          }

          return <LongText width={100}>{title || "N/A"}</LongText>;
        }
      });
    }

    if (viewSettingsValues.attach_slider) {
      newColumns.push({
        title: translate["Attach Slider"] || EN["Attach Slider"],
        dataIndex: "attach_slider",
        key: "attach_slider",
        sorter: true,
        width: 200,
        render: (text, record, index) => <Check check={record.attach_slider} />
      });
    }

    if (viewSettingsValues.price) {
      newColumns.push({
        title: translate["Price"] || EN["Price"],
        dataIndex: "price",
        key: "price",
        width: 130,
        sorter: true,
        render: (text, record, index) => (
          <Currency>{parseFloat(record.price).toFixed(2)}</Currency>
        )
      });
    }

    if (viewSettingsValues.createdAt) {
      newColumns.push({
        title: translate["Date"] || EN["Date"],
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: true,
        render: (text, record, index) => dateFormat(text)
      });
    }

    newColumns.push({
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",
      fixed: "right",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          style={{ minWidth: 130 }}
          buttons={[
            {
              key: "edit",
              text: translate["Edit"] || EN["Edit"],
              icon: ICONS.EDIT
            },
            {
              key: "delete",
              text: translate["Delete"] || EN["Delete"],
              icon: ICONS.DELETE
            }
          ]}
        />
      )
    });

    setColumns(newColumns);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setCurrentService(item);
        setVisibleServiceDrawer(true);
        break;

      case "delete":
        confirmModal({
          title: `${
            translate["Do you want to delete"] || EN["Do you want to delete"]
          } ${item.name}`,
          action: () => deleteService(item)
        });
        break;

      default:
        break;
    }
  };

  const deleteService = (item) => {
    REQUESTS.ECOMMERCE.SERVICES.DELETE(item.id, getServicesList);
  };

  const getServicesList = () => {
    setLoading(true);

    const query = {
      sort,
      limit,
      pagination: 1,
      page: currentPage
    };

    if (nameSearch) {
      query.search = JSON.stringify({ name: nameSearch });
    }

    if (categorySearch[0]) {
      query.category_name = categorySearch[0];
    }

    if (employerSearch[0]) {
      query.employer_name = employerSearch[0];
      if (query.pagination) {
        delete query.pagination;
        delete query.limit;
      }
    }

    let filter = {};

    if (selectedCategoryId) {
      filter.category_id = selectedCategoryId;
    }

    if (selectedEmployerId) {
      filter.employer_id = selectedEmployerId;
    }
    if (selectedPublishedStatus != null) {
      filter.attach_slider = selectedPublishedStatus;
    }
    const size = Object.keys(filter).length;

    if (size) {
      query.filter = JSON.stringify(filter);
    }

    function callback(data) {
      if (data.rows) {
        setServicesList(data.rows);
        setLimit(data.limit);
        setTotal(data.count);
      } else {
        setServicesList(data);
      }

      setLoading(false);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
    }

    function errorCallback() {
      setLoading(false);
    }

    REQUESTS.ECOMMERCE.SERVICES.GET(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    if (Object.values(filters)[0]) {
      setNameSearch(Object.values(filters)[0].join(""));
    } else {
      setNameSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  return (
    <ServiceContext.Provider
      value={{
        total,
        setVisibleServiceDrawer,
        currentService,
        visibleServiceDrawer,
        getServicesList,
        setVisibleViewSettingsDrawer,
        setVisibleFilterDrawer,
        visibleFilterDrawer,
        visibleViewSettingsDrawer,
        viewSettingsValues,
        setViewSettingsValues,
        setSelectedCategoryId,
        setSelectedEmployerId,
        selectedEmployerId,
        selectedCategoryId,
        selectedPublishedStatus,
        setSelectedPublishedStatus
      }}
    >
      <Page>
        <ServicesHeader />

        <div className="page-body">
          <TableHead />

          <Table
            loading={loading}
            rowKey="id"
            columns={columns}
            dataSource={servicesList}
            onChange={handleTableChange}
            pagination={{
              position: ["topCenter", "bottomCenter"],
              current: currentPage,
              total: total,
              showSizeChanger: true
            }}
            scroll={{ x: 1300 }}
            sticky={{
              offsetHeader: 60,
              offsetScroll: 0
            }}
          />
        </div>

        <ServiceDrawer />
      </Page>
    </ServiceContext.Provider>
  );
}
