import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Table, Form } from "antd";

import REQUESTS from "../../../server/requests";

import TableButtons from "../../elements/TableButtons";
import AddButtonComponent from "../../elements/AddButtonComponent";
import LongText from "../../elements/LongText";

import { dateFormat } from "../../../config/formats";

import ICONS from "../../../config/icons";
import confirmModal from "../../../config/confirmModal";
import EN from "../../../config/en";

import NewFeedbackDrawer from "./NewFeedbackDrawer";
import FeedbackTableHead from "./FeedbackTableHead";

export default function FeedbackSettingsDrawer({ visible, onClose }) {

    const translate = useSelector((state) => state.globalState.translate);

    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState(["id", "DESC"]);

    const [feedbackSettingsList, setfeedbackSettingsList] = useState([]);

    const [currentFeedback, setCurrentFeedback] = useState(null);
    const [visibleNewFeedback, setVisibleNewFeedback] = useState(false);

    useEffect(() => {
        if (!visibleNewFeedback) {
            setCurrentFeedback(null);
        }
    }, [visibleNewFeedback]);

    useEffect(() => {
        if (visible) {
            getFeedbackSettingsList();
        }
    }, [visible, sort]);

    const columns = [
        {
            width: 60,
            fixed: "left",
            title: "#",
            dataIndex: "id",
            key: "id",
            align: "center",
            render: (text, record, index) => index + 1,
        },

        {
            title: translate['Question title'] || EN['Question title'],
            dataIndex: "questions",
            key: "questions",
            render: (text, record, index) => <p className="text-ellipsis">{text}</p>,
        },

        {
            title: translate['Description'] || EN['Description'],
            dataIndex: "description",
            key: "description",
            render: (text, record, index) => <LongText>{text}</LongText>,
        },

        {
            title: translate['Date'] || EN['Date'],
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text, record, index) => dateFormat(text),
        },

        {
            fixed: "right",
            width: 100,
            title: "",
            dataIndex: "action",
            key: "action",
            align: "center",
            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    buttons={[
                        { key: "edit", text: translate['Edit'] || EN['Edit'], icon: ICONS.EDIT },
                        { key: "delete", text: translate['Delete'] || EN['Delete'], icon: ICONS.DELETE },
                    ]}
                />
            ),
        },
    ];

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case "edit":
                setCurrentFeedback(item);
                setVisibleNewFeedback(true);
                break;

            case "delete":
                confirmModal({
                    title: `${translate['Do you want to delete'] || EN['Do you want to delete']} ${item.questions}`,
                    action: () => deleteFeedback(item),
                });
                break;

            default:
                break;
        }
    };

    const deleteFeedback = (item) => {
        REQUESTS.FEEDBACK_QUESTIONS.DELETE(item.id, getFeedbackSettingsList);
    };

    const getFeedbackSettingsList = () => {
        setLoading(true);

        const query = { sort };

        function callback(data) {
            setfeedbackSettingsList(data);
            setLoading(false);
        }

        function errorCallback() {
            setLoading(false);
        }

        REQUESTS.FEEDBACK_QUESTIONS.GET(query, callback, errorCallback);
    };
    const handleTableChange = (sorter) => {
        if (sorter.field) {
          setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
    };
    return (
        <Drawer
            title={translate['Feedback Settings'] || EN['Feedback Settings']}
            placement="right"
            width={800}
            onClose={onClose}
            visible={visible}
           
        >
            <div className="global_header-title-drawer">
                <div className="title-in-drawer">
                    <h2>{translate['Feedback Settings'] || EN['Feedback Settings']}</h2>
                </div>
                <Form.Item>
                    <AddButtonComponent
                        text={translate['Add Feedback'] || EN['Add Feedback']}
                        onClick={() => setVisibleNewFeedback(true)}
                    />
                </Form.Item>
            </div>

            <div className="settings_icon_pt">
                <FeedbackTableHead />
            </div>

            <Table
                rowKey="id"
                loading={loading}
                columns={columns}
                dataSource={feedbackSettingsList}
                pagination={false}
                onChange={handleTableChange}
            />

            <NewFeedbackDrawer
                feedback={currentFeedback}
                getFeedbackSettingsList={getFeedbackSettingsList}
                visible={visibleNewFeedback}
                onClose={() => setVisibleNewFeedback(false)}
            />
        </Drawer>
    );
}
