import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import ICONS from "../../../config/icons";
import EN from "../../../config/en";
import IMAGES from "../../../config/images";

import { actions } from "../../../store";

import REQUESTS from "../../../server/requests";

import confirmModal from "../../../config/confirmModal";

import Message from "./Message";

export default React.memo(function Chat({ activeChat, selectActiveChat }) {
    const dispatch = useDispatch();

    const translate = useSelector((state) => state.globalState.translate);

    const { setNewMessageInRedux } = bindActionCreators(actions, dispatch);

    const [chatList, setChatList] = useState([]);

    const [newMessage, setNewMessage] = useState("");

    const profile = useSelector((state) => state.globalState.profile);
    const new_message = useSelector((state) => state.globalState.new_message);
    const seenChatGuest = useSelector((state) => state.globalState.seenChatGuest);
    const socketIsConnected = useSelector((state) => state.globalState.socketIsConnected);

    useEffect(() => {
        if (new_message && activeChat) {
            if (new_message.conversation_id === activeChat.id) {
                const arr = [...chatList];
                arr.push(new_message);

                setChatList(arr);

                REQUESTS.CONVERSATIONS.SEEN({ conversation_id: activeChat.id }, (data) => {});
            }
        }

        setTimeout(() => {
            setNewMessageInRedux(null);
        }, 1000);
    }, [new_message]);

    useEffect(() => {
        if (document.getElementById("chat_list")) {
            document.getElementById("chat_list").scrollTop = 100000000000;
        }
    }, [chatList]);

    useEffect(() => {
        if (activeChat) {
            getChat();
        }
    }, [activeChat]);

    useEffect(() => {
        if (seenChatGuest && activeChat) {
            if (seenChatGuest.conversation_id === activeChat.id) {
                getChat();
            }
        }
    }, [seenChatGuest]);

    const getChat = () => {
        const body = {
            filter: JSON.stringify({
                conversation_id: activeChat.id,
            }),
        };

        REQUESTS.CONVERSATIONS.CHAT(body, (data) => {
            setChatList(data);

            document.getElementById("chat_list").scrollTop = 100000000000;
        });
    };

    const deleteConversation = () => {
        confirmModal({
            title: `${translate['Do you want to delete'] || EN['Do you want to delete']} ${translate['Chat with'] || EN['Chat with']} ${activeChat?.guest?.name}`,
            action: () => {
                REQUESTS.CONVERSATIONS.DELETE(activeChat.id, (data) => {
                    selectActiveChat(null);
                });
            },
        });
    };

    const send = () => {
        const body = {
            conversation_id: activeChat.id,
            message: newMessage,
            message_type: "messages",
        };

        REQUESTS.CONVERSATIONS.NEW_MESSAGE(body, (data) => {
            setNewMessage("");
        });
    };

    const onEnterSend = (e) => {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            send();
        }
    };

    return (
        <div
            className="chat-section"
            style={{
                width: `calc(100% - ${activeChat ? 500 : 250}px)`,
            }}
        >
            {activeChat ? (
                <>
                    <header>
                        <div className="user-info">
                            <div className="user-online"></div>
                            <h1>
                                {translate['Chat with'] || EN['Chat with']} {activeChat?.guest?.name}
                            </h1>
                        </div>

                        <div className="delete-button" onClick={deleteConversation}>
                            {ICONS.DELETE}
                        </div>
                    </header>

                    <div id="chat_list">
                        {chatList.map((item, index) => {
                            return <Message key={item.id} item={item} profile={profile} />;
                        })}
                    </div>

                    <div className="new-message-area">
                        <textarea
                            disabled={!socketIsConnected}
                            className={`scroll-box ${!socketIsConnected ? "offline-textarea" : ""}`}
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder={
                                socketIsConnected
                                    ? `${translate['Write a message'] || EN['Write a message']}...`
                                    : translate['You are offline'] || EN['You are offline']
                            }
                            onKeyDown={onEnterSend}
                        />

                        {socketIsConnected && (
                            <div className="send-button" onClick={send}>
                                {ICONS.SEND}
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div className="select-conversation-section">
                    <img src={IMAGES.MESSAGES} alt="message" />
                    <h1>{translate['Select a Conversation'] || EN['Select a Conversation']}</h1>

                    <p>{translate['Try selecting a conversation or searching for someone specific'] || EN['Try selecting a conversation or searching for someone specific']}</p>
                </div>
            )}
        </div>
    );
});
