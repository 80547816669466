import { Divider, Space, Tabs } from "antd";
import { useSelector } from "react-redux";

export default function Translation({render}) {
  const {activeLanguages} = useSelector((state) => state.globalState);

  const renderElement = (item, index) => {
    if(render) {
      return (
        render(item, index)
      )
    }
  }

    return (
      <>
      
        <Tabs
        defaultActiveKey="1"
        tabPosition={'top'}
       
        items={activeLanguages?.map((item, index) => {
          return {
            label: <Space><img src={item?.icon} style={{width: 30}} /> <p style={{margin: 0}}>{item.name}</p></Space>,
            key: item.iso_code,
            children: renderElement(item, index),
          };
        })}
      />


</>

    )
}