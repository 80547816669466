import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Menu, Button } from "antd";

import ICONS from "../../config/icons";

/**
 * @param {function} handleMenuClick.isRequired -> the function should get a event and a row
 * @param {array} buttons.isRequired -> array items must have these keys` { key: "edit", text: "Edit", icon: icon},
 * @returns
 */

export default function TableButtons({ handleMenuClick = function () {}, buttons = [] }) {
    return (
        <Dropdown
            destroyPopupOnHide={true}
            trigger={["click"]}
            placement="bottomRight"
            className="table-action-button"
            overlay={
                <Menu onClick={handleMenuClick} style={{ minWidth: 130 }}>
                    {buttons.map((item) => {
                        return (
                            <Menu.Item key={item.key} icon={item.icon}>
                                {item.text}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            }
        >
            <Button icon={ICONS.ELLIPSIS} />
        </Dropdown>
    );
}

TableButtons.propTypes = {
    buttons: PropTypes.array.isRequired,
    handleMenuClick: PropTypes.func.isRequired,
};
