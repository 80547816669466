import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Layout, Menu } from "antd";

import EN from "../../config/en";
import NAVIGATORS from "../../config/navigators";

import Logo from "./Logo";
import REQUESTS from "../../server/requests";
import { APP_VERSION } from "../../config/config";

export default function Sidebar({ collapsed }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { translate, new_message, profile } = useSelector((state) => state.globalState);

  const [notReadCount, setNotReadCount] = useState(0);

  const [current, setCurrent] = useState("");

  useEffect(() => {
    const defaultNavigator = location.pathname;

    if (defaultNavigator == "/dashboard/location") {
      setCurrent(defaultNavigator + "s");
      return;
    }

    setCurrent(defaultNavigator);
  }, [location.pathname]);

  useEffect(() => {
    getNotReadCount();
  }, [new_message]);

  const defaultOpenedMenu = () => {
    let defaultNavigator = location.pathname;

    switch (defaultNavigator) {
      case NAVIGATORS.ORGANIZATION_CATEGORIES:
      case NAVIGATORS.ORGANIZATION_COMPANIES:
      case NAVIGATORS.HOST_GROUPS:
      case NAVIGATORS.LOCATIONS:
      case NAVIGATORS.LOCATION:
        return [translate["Organization"] || EN["Organization"]];

      case NAVIGATORS.PRODUCTS:
      case NAVIGATORS.ECOMMERCE_PRODUCTS:
      case NAVIGATORS.ECOMMERCE_CATEGORIES:
      case NAVIGATORS.ECOMMERCE_SERVICES:
        return [translate["E-Commerce"] || EN["E-Commerce"]];

      case NAVIGATORS.LIVE_TV_CATEGORIES:
      case NAVIGATORS.LIVE_TV_EPG_LINKS:
      case NAVIGATORS.LIVE_TV_TVCHANNELS:
        return [translate["Media"] || EN["Media"], translate["Live TV"] || EN["Live TV"]];

      case NAVIGATORS.MEDIA_VOD_GENRES:
      case NAVIGATORS.MEDIA_VOD_MOVES:
        return [translate["Media"] || EN["Media"], translate["Vod"] || EN["Vod"]];

      case NAVIGATORS.INFO_CENTER_CATEGORIES:
      case NAVIGATORS.INFO_CENTER:
        return [translate["Info Center"] || EN["Info Center"]];

      case NAVIGATORS.PROMOTIONS_NEWS:
      case NAVIGATORS.PROMOTIONS_CATEGORIES:
        return [translate["Promotions"] || EN["Promotions"]];
    }
  };

  const onClickItem = (e) => {
    setCurrent(e.key);

    navigate(e.key);
  };

  const getNotReadCount = () => {
    REQUESTS.CONVERSATIONS.NOT_READ_COUNT((data) => {
      setNotReadCount(data.count);
    });
  };

  return (
    <Layout.Sider
      id="sidebar"
      collapsible={null}
      collapsed={collapsed}
      collapsedWidth={80}
      width={250}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <div className="sidebar-logo-section">
        <header>
          <div className="logo-block">
            <Logo showTitle={!collapsed} />
          </div>

          <div className="version-block">
            <span>App version: {APP_VERSION}</span>
          </div>
        </header>
      </div>

      <div className="sidebar-menu">
        <Menu
          mode="inline"
          defaultOpenKeys={defaultOpenedMenu()}
          selectedKeys={current}
          onClick={onClickItem}
        >
          <Menu.Item
            key={NAVIGATORS.DASHBOARD_CONTENT}
            icon={
              <svg
                width="23"
                height="21"
                viewBox="0 0 23 21"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75 15.5H1.5C0.796875 15.5 0.25 16.0859 0.25 16.75V19.25C0.25 19.9531 0.796875 20.5 1.5 20.5H2.75C3.41406 20.5 4 19.9531 4 19.25V16.75C4 16.0859 3.41406 15.5 2.75 15.5ZM9 10.5H7.75C7.04688 10.5 6.5 11.0859 6.5 11.75V19.25C6.5 19.9531 7.04688 20.5 7.75 20.5H9C9.66406 20.5 10.25 19.9531 10.25 19.25V11.75C10.25 11.0859 9.66406 10.5 9 10.5ZM15.25 5.5H14C13.2969 5.5 12.75 6.08594 12.75 6.75V19.25C12.75 19.9531 13.2969 20.5 14 20.5H15.25C15.9141 20.5 16.5 19.9531 16.5 19.25V6.75C16.5 6.08594 15.9141 5.5 15.25 5.5ZM21.5 0.5H20.25C19.5469 0.5 19 1.08594 19 1.75V19.25C19 19.9531 19.5469 20.5 20.25 20.5H21.5C22.1641 20.5 22.75 19.9531 22.75 19.25V1.75C22.75 1.08594 22.1641 0.5 21.5 0.5Z"
                  fill="currentColor"
                />
              </svg>
            }
          >
            {translate["Dashboard"] || EN["Dashboard"]}
          </Menu.Item>

          <Menu.Item
            key={NAVIGATORS.MESSAGES}
            icon={
              <svg
                width="21"
                height="15"
                viewBox="0 0 21 15"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.1094 4.96094C19.2109 5.66406 18.0781 6.52344 14.0938 9.41406C13.3125 10 11.8672 11.2891 10.5 11.2891C9.09375 11.2891 7.6875 10 6.86719 9.41406C2.88281 6.52344 1.75 5.66406 0.851562 4.96094C0.695312 4.84375 0.5 4.96094 0.5 5.15625V13.125C0.5 14.1797 1.32031 15 2.375 15H18.625C19.6406 15 20.5 14.1797 20.5 13.125V5.15625C20.5 4.96094 20.2656 4.84375 20.1094 4.96094ZM10.5 10C11.3984 10.0391 12.6875 8.86719 13.3516 8.39844C18.5469 4.64844 18.9375 4.29688 20.1094 3.35938C20.3438 3.20312 20.5 2.92969 20.5 2.61719V1.875C20.5 0.859375 19.6406 0 18.625 0H2.375C1.32031 0 0.5 0.859375 0.5 1.875V2.61719C0.5 2.92969 0.617188 3.20312 0.851562 3.35938C2.02344 4.29688 2.41406 4.64844 7.60938 8.39844C8.27344 8.86719 9.5625 10.0391 10.5 10Z"
                  fill="currentColor"
                />
              </svg>
            }
          >
            {translate["Messages"] || EN["Messages"]}

            {notReadCount > 0 && (
              <span style={{ float: "right", fontSize: 14, color: "#2f6bff" }}>
                {notReadCount}
              </span>
            )}
          </Menu.Item>

          <Menu.SubMenu
            key={translate["Organization"] || EN["Organization"]}
            title={translate["Organization"] || EN["Organization"]}
            icon={
              <svg
                width="26"
                height="21"
                viewBox="0 0 36 32"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.75 14H27.1875C27.5625 14 28 13.625 28 13.25V10.8125C28 10.4375 27.5625 10 27.1875 10H24.75C24.375 10 24 10.4375 24 10.8125V13.25C24 13.625 24.375 14 24.75 14ZM16.75 8H19.1875C19.5625 8 20 7.625 20 7.25V4.8125C20 4.4375 19.5625 4 19.1875 4H16.75C16.375 4 16 4.4375 16 4.8125V7.25C16 7.625 16.375 8 16.75 8ZM24.75 8H27.1875C27.5625 8 28 7.625 28 7.25V4.8125C28 4.4375 27.5625 4 27.1875 4H24.75C24.375 4 24 4.4375 24 4.8125V7.25C24 7.625 24.375 8 24.75 8ZM8.75 8H11.1875C11.5625 8 12 7.625 12 7.25V4.8125C12 4.4375 11.5625 4 11.1875 4H8.75C8.375 4 8 4.4375 8 4.8125V7.25C8 7.625 8.375 8 8.75 8ZM16.75 14H19.1875C19.5625 14 20 13.625 20 13.25V10.8125C20 10.4375 19.5625 10 19.1875 10H16.75C16.375 10 16 10.4375 16 10.8125V13.25C16 13.625 16.375 14 16.75 14ZM35.5 2C35.75 2 36 1.8125 36 1.5V0.5C36 0.25 35.75 0 35.5 0H0.5C0.1875 0 0 0.25 0 0.5V1.5C0 1.8125 0.1875 2 0.5 2H1.9375V30H0.5C0.1875 30 0 30.25 0 30.5V31.5C0 31.8125 0.1875 32 0.5 32H35.5C35.75 32 36 31.8125 36 31.5V30.5C36 30.25 35.75 30 35.5 30H34V2H35.5ZM20 30H16V25C16 24.5 16.4375 24 17 24H19C19.5 24 20 24.5 20 25V30ZM32 30H22V25C22 23.375 20.625 22 19 22H17C15.3125 22 14 23.375 14 25V30H3.9375V2H32V30ZM8.75 14H11.1875C11.5625 14 12 13.625 12 13.25V10.8125C12 10.4375 11.5625 10 11.1875 10H8.75C8.375 10 8 10.4375 8 10.8125V13.25C8 13.625 8.375 14 8.75 14ZM10.4375 23.875L11.4375 24C11.6875 24.0625 11.9375 23.875 12 23.625C12.625 20.9375 15.125 19 18 19C20.8125 19 23.3125 20.9375 23.9375 23.625C24 23.875 24.25 24.0625 24.5 24L25.5 23.875C25.8125 23.875 26 23.5625 25.9375 23.3125C25.1875 19.6875 21.8125 17 18 17C14.125 17 10.75 19.6875 10 23.3125C9.9375 23.5625 10.125 23.875 10.4375 23.875Z"
                  fill="currentColor"
                />
              </svg>
            }
          >
            {profile?.is_super_admin && (
              <Menu.Item key={NAVIGATORS.ORGANIZATION_COMPANIES}>
                {translate["Companies"] || EN["Companies"]}
              </Menu.Item>
            )}

            <Menu.Item key={NAVIGATORS.ORGANIZATION_CATEGORIES}>
              {translate["Categories"] || EN["Categories"]}
            </Menu.Item>
            <Menu.Item key={NAVIGATORS.HOST_GROUPS}>
              {translate["Guest groups"] || EN["Guest groups"]}
            </Menu.Item>
            <Menu.Item key={NAVIGATORS.LOCATIONS}>
              {translate["Location"] || EN["Location"]}
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item
            key={NAVIGATORS.PACKAGES}
            icon={
              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.625 0.75H2.375C1.32031 0.75 0.5 1.60938 0.5 2.625V16.375C0.5 17.4297 1.32031 18.25 2.375 18.25H18.625C19.6406 18.25 20.5 17.4297 20.5 16.375V2.625C20.5 1.60938 19.6406 0.75 18.625 0.75ZM15.5 3.875C15.5 3.21094 16.0469 2.625 16.75 2.625C17.4141 2.625 18 3.21094 18 3.875C18 4.57812 17.4141 5.125 16.75 5.125C16.0469 5.125 15.5 4.57812 15.5 3.875ZM11.75 3.875C11.75 3.21094 12.2969 2.625 13 2.625C13.6641 2.625 14.25 3.21094 14.25 3.875C14.25 4.57812 13.6641 5.125 13 5.125C12.2969 5.125 11.75 4.57812 11.75 3.875ZM8 3.875C8 3.21094 8.54688 2.625 9.25 2.625C9.91406 2.625 10.5 3.21094 10.5 3.875C10.5 4.57812 9.91406 5.125 9.25 5.125C8.54688 5.125 8 4.57812 8 3.875ZM18.625 16.1406C18.625 16.2969 18.5078 16.375 18.3906 16.375H2.60938C2.45312 16.375 2.375 16.2969 2.375 16.1406V7H18.625V16.1406Z"
                  fill="currentColor"
                />
              </svg>
            }
          >
            {translate["Packages"] || EN["Packages"]}
          </Menu.Item>

          <Menu.Item
            key={NAVIGATORS.ORDERS}
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path
                  d="M256 0v128h128L256 0zM288 256H96v64h192V256zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM64 72C64 67.63 67.63 64 72 64h80C156.4 64 160 67.63 160 72v16C160 92.38 156.4 96 152 96h-80C67.63 96 64 92.38 64 88V72zM64 136C64 131.6 67.63 128 72 128h80C156.4 128 160 131.6 160 136v16C160 156.4 156.4 160 152 160h-80C67.63 160 64 156.4 64 152V136zM320 440c0 4.375-3.625 8-8 8h-80C227.6 448 224 444.4 224 440v-16c0-4.375 3.625-8 8-8h80c4.375 0 8 3.625 8 8V440zM320 240v96c0 8.875-7.125 16-16 16h-224C71.13 352 64 344.9 64 336v-96C64 231.1 71.13 224 80 224h224C312.9 224 320 231.1 320 240z"
                  fill="currentColor"
                />
              </svg>
            }
          >
            {translate["Orders"] || EN["Orders"]}
          </Menu.Item>

          <Menu.Item
            key={NAVIGATORS.MEDIA_LIBRARY}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="19"
                viewBox="0 0 45 45"
              >
                <path
                  d="M11 36Q9.8 36 8.9 35.1Q8 34.2 8 33V7Q8 5.8 8.9 4.9Q9.8 4 11 4H23.25L26.25 7H43Q44.2 7 45.1 7.9Q46 8.8 46 10V33Q46 34.2 45.1 35.1Q44.2 36 43 36ZM11 33H43Q43 33 43 33Q43 33 43 33V10Q43 10 43 10Q43 10 43 10H25L22 7H11Q11 7 11 7Q11 7 11 7V33Q11 33 11 33Q11 33 11 33ZM40.5 42H5Q3.8 42 2.9 41.1Q2 40.2 2 39V10H5V39Q5 39 5 39Q5 39 5 39H40.5ZM16.7 27.45H37.3L30.7 18.65L25.2 25.95L21.25 21.65ZM11 33Q11 33 11 33Q11 33 11 33V7Q11 7 11 7Q11 7 11 7V10Q11 10 11 10Q11 10 11 10V33Q11 33 11 33Q11 33 11 33Z"
                  fill="currentColor"
                />
              </svg>
            }
          >
            {translate["Media Library"] || EN["Media Library"]}
          </Menu.Item>

          <Menu.Item
            key={NAVIGATORS.MONITORING}
            icon={
              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.25 5.75C19.9141 5.75 20.5 5.20312 20.5 4.5V2C20.5 1.33594 19.9141 0.75 19.25 0.75H1.75C1.04688 0.75 0.5 1.33594 0.5 2V4.5C0.5 5.20312 1.04688 5.75 1.75 5.75H19.25ZM17.375 2.3125C17.8828 2.3125 18.3125 2.74219 18.3125 3.25C18.3125 3.79688 17.8828 4.1875 17.375 4.1875C16.8281 4.1875 16.4375 3.79688 16.4375 3.25C16.4375 2.74219 16.8281 2.3125 17.375 2.3125ZM14.875 2.3125C15.3828 2.3125 15.8125 2.74219 15.8125 3.25C15.8125 3.79688 15.3828 4.1875 14.875 4.1875C14.3281 4.1875 13.9375 3.79688 13.9375 3.25C13.9375 2.74219 14.3281 2.3125 14.875 2.3125ZM19.25 12C19.9141 12 20.5 11.4531 20.5 10.75V8.25C20.5 7.58594 19.9141 7 19.25 7H1.75C1.04688 7 0.5 7.58594 0.5 8.25V10.75C0.5 11.4531 1.04688 12 1.75 12H19.25ZM17.375 8.5625C17.8828 8.5625 18.3125 8.99219 18.3125 9.5C18.3125 10.0469 17.8828 10.4375 17.375 10.4375C16.8281 10.4375 16.4375 10.0469 16.4375 9.5C16.4375 8.99219 16.8281 8.5625 17.375 8.5625ZM14.875 8.5625C15.3828 8.5625 15.8125 8.99219 15.8125 9.5C15.8125 10.0469 15.3828 10.4375 14.875 10.4375C14.3281 10.4375 13.9375 10.0469 13.9375 9.5C13.9375 8.99219 14.3281 8.5625 14.875 8.5625ZM19.25 18.25C19.9141 18.25 20.5 17.7031 20.5 17V14.5C20.5 13.8359 19.9141 13.25 19.25 13.25H1.75C1.04688 13.25 0.5 13.8359 0.5 14.5V17C0.5 17.7031 1.04688 18.25 1.75 18.25H19.25ZM17.375 14.8125C17.8828 14.8125 18.3125 15.2422 18.3125 15.75C18.3125 16.2969 17.8828 16.6875 17.375 16.6875C16.8281 16.6875 16.4375 16.2969 16.4375 15.75C16.4375 15.2422 16.8281 14.8125 17.375 14.8125ZM14.875 14.8125C15.3828 14.8125 15.8125 15.2422 15.8125 15.75C15.8125 16.2969 15.3828 16.6875 14.875 16.6875C14.3281 16.6875 13.9375 16.2969 13.9375 15.75C13.9375 15.2422 14.3281 14.8125 14.875 14.8125Z"
                  fill="currentColor"
                />
              </svg>
            }
          >
            {translate["Monitoring"] || EN["Monitoring"]}
          </Menu.Item>

          <Menu.SubMenu
            key={translate["Info Center"] || EN["Info Center"]}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="currentColor"
              >
                <rect fill="none" height="24" width="24" />
                <path d="M12,7V3H2v18h20V7H12z M10,19H4v-2h6V19z M10,15H4v-2h6V15z M10,11H4V9h6V11z M10,7H4V5h6V7z M20,19h-8V9h8V19z M18,11h-4v2 h4V11z M18,15h-4v2h4V15z" />
              </svg>
            }
            title={translate["Info Center"] || EN["Info Center"]}
          >
            <Menu.Item key={NAVIGATORS.INFO_CENTER_CATEGORIES}>
              {translate["Categories"] || EN["Categories"]}
            </Menu.Item>

            <Menu.Item key={NAVIGATORS.INFO_CENTER}>
              {translate["Info List"] || EN["Info List"]}
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu
            key={translate["Promotions"] || EN["Promotions"]}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="25px"
                viewBox="0 0 24 24"
                width="25px"
              >
                <path
                  d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"
                  fill="currentColor"
                />
              </svg>
            }
            title={translate["Promotions"] || EN["Promotions"]}
          >
            <Menu.Item key={NAVIGATORS.PROMOTIONS_CATEGORIES}>
              {translate["Categories"] || EN["Categories"]}
            </Menu.Item>

            <Menu.Item key={NAVIGATORS.PROMOTIONS_NEWS}>
              {translate["News"] || EN["News"]}
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu
            key={translate["E-Commerce"] || EN["E-Commerce"]}
            icon={
              <svg
                width="26"
                height="21"
                viewBox="0 0 26 21"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.4141 9.32812L11.1719 1.08594C10.8594 0.773438 10.2734 0.5 9.84375 0.5H1.875C0.820312 0.5 0 1.35938 0 2.375V10.3828C0 10.8125 0.234375 11.3984 0.546875 11.7109L8.78906 19.9531C9.53125 20.6953 10.7422 20.6953 11.4453 19.9531L19.4141 11.9844C20.1562 11.2812 20.1562 10.0703 19.4141 9.32812ZM4.375 6.75C3.32031 6.75 2.5 5.92969 2.5 4.875C2.5 3.85938 3.32031 3 4.375 3C5.39062 3 6.25 3.85938 6.25 4.875C6.25 5.92969 5.39062 6.75 4.375 6.75ZM24.4141 11.9844C25.1562 11.2812 25.1562 10.0703 24.4141 9.32812L16.1719 1.08594C15.8594 0.773438 15.2734 0.5 14.8438 0.5H12.9297L20.5859 8.19531C21.25 8.85938 21.6406 9.71875 21.6406 10.6562C21.6406 11.5938 21.25 12.4922 20.5859 13.1562L13.7891 19.9531C14.5312 20.6953 15.7422 20.6953 16.4453 19.9531L24.4141 11.9844Z"
                  fill="currentColor"
                />
              </svg>
            }
            title={translate["E-Commerce"] || EN["E-Commerce"]}
          >
            <Menu.Item key={NAVIGATORS.ECOMMERCE_CATEGORIES}>
              {translate["Categories"] || EN["Categories"]}
            </Menu.Item>

            <Menu.Item key={NAVIGATORS.ECOMMERCE_PRODUCTS}>
              {translate["Products"] || EN["Products"]}
            </Menu.Item>

            <Menu.Item key={NAVIGATORS.ECOMMERCE_SERVICES}>
              {translate["Services"] || EN["Services"]}
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu
            key={translate["Media"] || EN["Media"]}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="currentColor"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M9 10v8l7-4zm12-4h-7.58l3.29-3.29L16 2l-4 4h-.03l-4-4-.69.71L10.56 6H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 14H3V8h18v12z"
                  fill="currentColor"
                />
              </svg>
            }
            title={translate["Media"] || EN["Media"]}
          >
            <Menu.SubMenu
              key={translate["Live TV"] || EN["Live TV"]}
              title={translate["Live TV"] || EN["Live TV"]}
            >
              <Menu.Item key={NAVIGATORS.LIVE_TV_CATEGORIES}>
                {translate["Categories"] || EN["Categories"]}
              </Menu.Item>

              <Menu.Item key={NAVIGATORS.LIVE_TV_EPG_LINKS}>
                {translate["Epg Links"] || EN["Epg Links"]}
              </Menu.Item>
              <Menu.Item key={NAVIGATORS.LIVE_TV_TVCHANNELS}>
                {translate["Tv Channel"] || EN["Tv Channel"]}
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key={translate["Vod"] || EN["Vod"]}
              title={translate["Vod"] || EN["Vod"]}
            >
              <Menu.Item key={NAVIGATORS.MEDIA_VOD_GENRES}>
                {translate["Genres"] || EN["Genres"]}
              </Menu.Item>

              <Menu.Item key={NAVIGATORS.MEDIA_VOD_MOVES}>
                {translate["Movies"] || EN["Movies"]}
              </Menu.Item>
            </Menu.SubMenu>
          </Menu.SubMenu>

          <Menu.Item
            key={NAVIGATORS.APPLICATION}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                viewBox="0 0 48 48"
                fill="currentColor"
              >
                <path d="M17.8 42H9q-1.2 0-2.1-.9Q6 40.2 6 39v-8.8q2.2-.25 3.775-1.725Q11.35 27 11.35 24.85t-1.575-3.625Q8.2 19.75 6 19.5v-8.8q0-1.2.9-2.1.9-.9 2.1-.9h8.85q.55-2 1.975-3.35T23.25 3q2 0 3.425 1.35Q28.1 5.7 28.65 7.7h8.65q1.2 0 2.1.9.9.9.9 2.1v8.65q2 .55 3.275 2.075 1.275 1.525 1.275 3.525t-1.275 3.35Q42.3 29.65 40.3 30.2V39q0 1.2-.9 2.1-.9.9-2.1.9h-8.8q-.25-2.4-1.775-3.875T23.15 36.65q-2.05 0-3.575 1.475Q18.05 39.6 17.8 42ZM9 39h6.5q1.25-3.05 3.5-4.2 2.25-1.15 4.15-1.15 1.9 0 4.15 1.15 2.25 1.15 3.5 4.2h6.5V27.25h2.25q1 0 1.65-.65.65-.65.65-1.65 0-1-.65-1.65-.65-.65-1.65-.65H37.3V10.7H25.55V8.3q0-1-.65-1.65Q24.25 6 23.25 6q-1 0-1.65.65-.65.65-.65 1.65v2.4H9v6.5q2.4.9 3.875 3t1.475 4.65q0 2.55-1.475 4.65Q11.4 31.6 9 32.5Zm16.45-16.5Z" />
              </svg>
            }
          >
            {translate["Application"] || EN["Application"]}
          </Menu.Item>

          <Menu.Item
            key={NAVIGATORS.SCREEN_MIRRORING}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                viewBox="0 0 48 48"
                fill="currentColor"
              >
                <path d="M41 17.5V11h-6.5V8H41q1.2 0 2.1.9.9.9.9 2.1v6.5Zm-37 0V11q0-1.2.9-2.1Q5.8 8 7 8h6.5v3H7v6.5ZM34.5 40v-3H41v-6.5h3V37q0 1.2-.9 2.1-.9.9-2.1.9ZM7 40q-1.2 0-2.1-.9Q4 38.2 4 37v-6.5h3V37h6.5v3Zm3-6V14h28v20Zm3-3h22V17H13Zm0 0V17v14Z" />
              </svg>
            }
          >
            {translate["Screen Mirroring"] || EN["Screen Mirroring"]}
          </Menu.Item>

          <Menu.Item
            key={NAVIGATORS.TV_APPLICATION}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="currentColor"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z"
                  fill="currentColor"
                />
              </svg>
            }
          >
            {translate["Tv Application"] || EN["Tv Application"]}
          </Menu.Item>

          <Menu.Item
            key={NAVIGATORS.TRANSLATION}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 48 48"
                width="24px"
                fill="currentColor"
              >
                <path
                  d="M23.75 44 32.8 20H36.9L46.2 44H41.85L39.8 37.7H30.2L27.85 44ZM31.3 34.2H38.4L34.9 24.5H34.8ZM8 38 5.25 35.25 15.45 25.05Q13.55 22.85 12.075 20.625Q10.6 18.4 9.5 16H13.85Q14.7 17.65 15.725 19.125Q16.75 20.6 18.05 22.15Q20.3 19.8 21.8 17.275Q23.3 14.75 24.35 12H2V8H16V4H20V8H34V12H28.35Q27.25 15.45 25.425 18.775Q23.6 22.1 20.95 25.1L25.85 30.05L24.35 34.1L18 28Z"
                  fill="currentColor"
                />
              </svg>
            }
          >
            {translate["Translation"] || EN["Translation"]}
          </Menu.Item>
          <Menu.Item
            id="settingsId"
            key={NAVIGATORS.SETTINGS}
            icon={
              <svg
                width="19"
                height="21"
                viewBox="0 0 19 21"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.5234 12.8438L16.8438 11.9062C17.0391 10.9688 17.0391 10.0703 16.8438 9.13281L18.5234 8.19531C18.7188 8.07812 18.7969 7.84375 18.7188 7.64844C18.2891 6.24219 17.5469 4.99219 16.6094 3.9375C16.4531 3.78125 16.2188 3.74219 16.0234 3.85938L14.3438 4.79688C13.6406 4.21094 12.8594 3.74219 12 3.42969V1.51562C12 1.32031 11.8438 1.125 11.6094 1.08594C10.1641 0.734375 8.71875 0.773438 7.35156 1.08594C7.11719 1.125 7 1.32031 7 1.51562V3.42969C6.10156 3.74219 5.32031 4.21094 4.61719 4.83594L2.9375 3.85938C2.74219 3.74219 2.50781 3.78125 2.35156 3.9375C1.41406 4.99219 0.671875 6.24219 0.242188 7.64844C0.164062 7.84375 0.242188 8.07812 0.4375 8.19531L2.11719 9.13281C1.96094 10.0703 1.96094 10.9688 2.11719 11.9062L0.4375 12.8438C0.242188 12.9609 0.164062 13.1953 0.242188 13.3906C0.671875 14.7969 1.41406 16.0469 2.35156 17.1016C2.50781 17.2578 2.74219 17.2969 2.9375 17.1797L4.61719 16.2422C5.32031 16.8281 6.10156 17.2969 7 17.6094V19.5234C7 19.7188 7.15625 19.9141 7.35156 19.9922C8.79688 20.3047 10.2422 20.2656 11.6094 19.9922C11.8438 19.9141 12 19.7188 12 19.5234V17.6094C12.8594 17.2969 13.6406 16.8281 14.3438 16.2422L16.0234 17.1797C16.2188 17.2969 16.4531 17.2578 16.6094 17.1016C17.5859 16.0469 18.2891 14.7969 18.7578 13.3906C18.7969 13.1953 18.7188 12.9609 18.5234 12.8438ZM9.5 13.625C7.74219 13.625 6.375 12.2578 6.375 10.5C6.375 8.78125 7.74219 7.375 9.5 7.375C11.2188 7.375 12.625 8.78125 12.625 10.5C12.625 12.2578 11.2188 13.625 9.5 13.625Z"
                  fill="currentColor"
                />
              </svg>
            }
          >
            {translate["Settings"] || EN["Settings"]}
          </Menu.Item>
          <Menu.Item
            id="settingsId"
            key={NAVIGATORS.HELP_INFO}
            icon={
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="info-circle"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm32 664c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V456c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272zm-32-344a48.01 48.01 0 010-96 48.01 48.01 0 010 96z" />
                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm32 664c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V456c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272zm-32-344a48.01 48.01 0 010-96 48.01 48.01 0 010 96z" />
              </svg>
            }
          >
            {translate["Help-info"] || EN["Help-info"]}
          </Menu.Item>
        </Menu>
      </div>
    </Layout.Sider>
  );
}
