import URLS from "./urls";
import request from "./request";
import requestQuery from "./requestQuery";

const REQUESTS = {
  // a
  AIRPORTS: {
    GET: function (callback, errorCallback) {
      request("get", URLS.AIRPORT, {}, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.AIRPORT, body, callback, errorCallback);
    },

    KEY: {
      GET: function (callback, errorCallback) {
        request("get", `${URLS.AIRPORT}/api`, {}, callback, errorCallback);
      },

      EDIT: function (body, callback, errorCallback) {
        request("put", ` ${URLS.AIRPORT}/api`, body, callback, errorCallback);
      },
    },
  },

  APPLICATION: {
    GET: function (query, callback, errorCallback) {
      request("get", URLS.APPLICATION + requestQuery(query), {}, callback, errorCallback);
    },
    ADD: function (body, callback, errorCallback) {
      request("post", URLS.APPLICATION, body, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.APPLICATION, body, callback, errorCallback);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.APPLICATION, { id }, callback, errorCallback);
    },
  },
  // b
  // c
  COUNTRY: {
    GET: function (query, callback, errorCallback) {
      request("get", URLS.COUNTRY + requestQuery(query), {}, callback, errorCallback);
    },
    ADD: function (body, callback, errorCallback) {
      request("post", URLS.COUNTRY, body, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.COUNTRY, body, callback, errorCallback);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.COUNTRY, { id }, callback, errorCallback);
    },
  },

  CURRENCY: {
    GET: function (callback, errorCallback) {
      request("get", URLS.CURRENCY, {}, callback, errorCallback);
    },
  },

  CONVERSATIONS: {
    GET: (query, callback, errorCallback) => {
      request(
        "get",
        URLS.CONVERSATIONS + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },

    ADD: function (body, callback, errorCallback) {
      request("post", URLS.CONVERSATIONS, body, callback, errorCallback);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.CONVERSATIONS, { id }, callback, errorCallback);
    },

    CHAT: function (query, callback, errorCallback) {
      request("get", URLS.CHAT + requestQuery(query), {}, callback, errorCallback);
    },

    NEW_MESSAGE: function (body, callback, errorCallback) {
      request("post", URLS.CHAT, body, callback, errorCallback);
    },

    SEEN: function (body, callback, errorCallback) {
      request("post", `${URLS.CHAT}/seen`, body, callback, errorCallback);
    },

    NOT_READ_COUNT: (callback, errorCallback) => {
      request("get", `${URLS.CONVERSATIONS}/not_read_count`, {}, callback, errorCallback);
    },
  },

  // d
  DASHBOARD: {
    STATISTICS_DASHBOARD: function (query, callback, errorCallback) {
      request(
        "get",
        URLS.STATISTICS_DASHBOARD + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },

    STATISTICS_GUESTS: function (query, callback, errorCallback) {
      request(
        "get",
        URLS.STATISTICS_GUESTS + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },

    STATISTICS_ORDER: function (query, callback, errorCallback) {
      request(
        "get",
        URLS.STATISTICS_ORDER + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },

    STATISTICS_GUESTS_COUNTRY: function (query, callback, errorCallback) {
      request(
        "get",
        URLS.STATISTICS_GUESTS_COUNTRY + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },

    STATISTICS_FEEDBACK_QUESTIONS: function (query, callback, errorCallback) {
      request(
        "get",
        URLS.STATISTICS_FEEDBACK_QUESTIONS + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },
  },
  // e
  EMPLOYERS: {
    GET: function (query, callback, errorCallback) {
      request("get", URLS.EMPLOYERS + requestQuery(query), {}, callback, errorCallback);
    },

    ADD: function (body, callback, errorCallback) {
      request("post", URLS.EMPLOYERS, body, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.EMPLOYERS, body, callback, errorCallback);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.EMPLOYERS, { id }, callback, errorCallback);
    },

    ROLES: function (callback, errorCallback) {
      request("get", URLS.ROLES, {}, callback, errorCallback);
    },

    LOGIN: function (body, callback, errorCallback) {
      request("post", URLS.LOGIN, body, callback, errorCallback);
    },

    RESET_PASSWORD: function (body, callback, errorCallback) {
      request("post", URLS.RESET_PASSWORD, body, callback, errorCallback);
    },

    CONFIRM_PASSWORD: function (body, callback, errorCallback) {
      request("post", URLS.CONFIRM_PASSWORD, body, callback, errorCallback);
    },

    PROFILE: {
      GET: (callback, errorCallback) => {
        request("get", URLS.GET_PROFILE, {}, callback, errorCallback);
      },
    },
    PASWWORD: {
      ADD: function (body, callback, errorCallback) {
        request("post", URLS.EDIT_PASSWORD, body, callback, errorCallback);
      },
    },
  },

  ECOMMERCE: {
    PRODUCTS: {
      GET: function (query, callback, errorCallback) {
        request("get", URLS.PRODUCTS + requestQuery(query), {}, callback, errorCallback);
      },

      ADD: function (body, callback, errorCallback) {
        request("post", URLS.PRODUCTS, body, callback, errorCallback);
      },

      EDIT: function (body, callback, errorCallback) {
        request("put", URLS.PRODUCTS, body, callback, errorCallback);
      },

      DELETE: function (id, callback, errorCallback) {
        request("delete", URLS.PRODUCTS, { id }, callback, errorCallback);
      },

      STATISTICS: function (callback, errorCallback) {
        request("get", `${URLS.PRODUCTS}/statistics`, {}, callback, errorCallback);
      },
    },

    CATEGORIES: {
      GET: function (query, callback, errorCallback) {
        request(
          "get",
          URLS.ECOMMERCIAL_CATEGORIES + requestQuery(query),
          {},
          callback,
          errorCallback
        );
      },

      ADD: function (body, callback, errorCallback) {
        request("post", URLS.ECOMMERCIAL_CATEGORIES, body, callback, errorCallback);
      },

      EDIT: function (body, callback, errorCallback) {
        request("put", URLS.ECOMMERCIAL_CATEGORIES, body, callback, errorCallback);
      },

      DELETE: function (id, callback, errorCallback) {
        request("delete", URLS.ECOMMERCIAL_CATEGORIES, { id }, callback, errorCallback);
      },
      STATISTICS: function (callback, errorCallback) {
        request(
          "get",
          `${URLS.ECOMMERCIAL_CATEGORIES}/statistics`,
          {},
          callback,
          errorCallback
        );
      },
    },

    SERVICES: {
      GET: function (query, callback, errorCallback) {
        request("get", URLS.SERVICES + requestQuery(query), {}, callback, errorCallback);
      },

      ADD: function (body, callback, errorCallback) {
        request("post", URLS.SERVICES, body, callback, errorCallback);
      },

      EDIT: function (body, callback, errorCallback) {
        request("put", URLS.SERVICES, body, callback, errorCallback);
      },

      DELETE: function (id, callback, errorCallback) {
        request("delete", URLS.SERVICES, { id }, callback, errorCallback);
      },

      STATISTICS: function (callback, errorCallback) {
        request("get", `${URLS.SERVICES}/statistics`, {}, callback, errorCallback);
      },
    },
  },

  EPG: {
    GET: function (query, callback, errorCallback) {
      request("get", URLS.EPG + requestQuery(query), {}, callback, errorCallback);
    },

    ADD: function (body, callback, errorCallback) {
      request("post", URLS.EPG, body, callback, errorCallback, true);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.EPG, body, callback, errorCallback, true);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.EPG, { id }, callback, errorCallback);
    },

    STATISTICS: function (callback, errorCallback) {
      request("get", `${URLS.EPG}/statistics`, {}, callback, errorCallback);
    },

    ENABLE: function (body, callback, errorCallback) {
      request("put", URLS.EPG, body, callback, errorCallback);
    },

    CHANNELS: {
      GET: function (query, callback, errorCallback) {
        request(
          "get",
          URLS.EPG_CHANNELS + requestQuery(query),
          {},
          callback,
          errorCallback
        );
      },
    },
  },

  EXIT_APP: {
    GET: (callback, errorCallback) => {
      request("get", URLS.EXIT, {}, callback, errorCallback);
    },

    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.EXIT, body, callback, errorCallback);
    },
  },

  // f
  FEEDBACK_QUESTIONS: {
    GET: function (query, callback, errorCallback) {
      request(
        "get",
        URLS.FEEDBACK_QUESTIONS + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },
    ADD: function (body, callback, errorCallback) {
      request("post", URLS.FEEDBACK_QUESTIONS, body, callback, errorCallback);
    },
    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.FEEDBACK_QUESTIONS, body, callback, errorCallback);
    },
    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.FEEDBACK_QUESTIONS, { id }, callback, errorCallback);
    },
  },
  // g
  GUEST: {
    ADD: function (body, callback, errorCallback) {
      request("post", URLS.GUESTS, body, callback, errorCallback);
    },

    DELETE: function (guest_id, callback, errorCallback) {
      request("post", URLS.CHECK_OUT, { guest_id }, callback, errorCallback);
    },
  },

  GUI: {
    GET: function (query, callback, errorCallback) {
      request("get", URLS.GUI + requestQuery(query), {}, callback, errorCallback);
    },

    ADD: function (body, callback, errorCallback) {
      request("post", URLS.GUI, body, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.GUI, body, callback, errorCallback);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.GUI, { id }, callback, errorCallback);
    },
  },

  // h
  HOST_GROUPS: {
    GET: function (callback, errorCallback) {
      request("get", URLS.GUESTS_GROUPS, {}, callback, errorCallback);
    },
    GET_APPLICATION_FORM: function (query, callback, errorCallback) {
      request("get", URLS.GUEST_APPLICATIONS, {}, callback, errorCallback);
    },
  },
  HISTORY: {
    FEEDBACK: function (query, callback, errorCallback) {
      request(
        "get",
        URLS.FEEDBACK_HISTORY + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },
  },
  // i
  IMPORT_CVS: (body, callback, errorCallback) => {
    request("post", URLS.IMPORT_CSV, body, callback, errorCallback, true);
  },

  INFO_CENTER: {
    INFO: {
      GET: function (query, callback, errorCallback) {
        request(
          "get",
          URLS.INFORMATION + requestQuery(query),
          {},
          callback,
          errorCallback
        );
      },

      ADD: function (body, callback, errorCallback) {
        request("post", URLS.INFORMATION, body, callback, errorCallback);
      },

      EDIT: function (body, callback, errorCallback) {
        request("put", URLS.INFORMATION, body, callback, errorCallback);
      },

      DELETE: function (id, callback, errorCallback) {
        request("delete", URLS.INFORMATION, { id }, callback, errorCallback);
      },

      STATISTICS: function (callback, errorCallback) {
        request("get", `${URLS.INFORMATION}/statistics`, {}, callback, errorCallback);
      },
    },
    GALLERY: {
      GET: function (query, callback, errorCallback) {
        request(
          "get",
          URLS.INFORMATION_GALLERY + requestQuery(query),
          {},
          callback,
          errorCallback
        );
      },

      ADD: function (body, callback, errorCallback) {
        request("post", URLS.INFORMATION_GALLERY, body, callback, errorCallback);
      },

      EDIT: function (body, callback, errorCallback) {
        request("put", URLS.INFORMATION_GALLERY, body, callback, errorCallback);
      },

      DELETE: function (id, callback, errorCallback) {
        request("delete", URLS.INFORMATION_GALLERY, { id }, callback, errorCallback);
      },
    },
    CATEGORIES: {
      GET: function (query, callback, errorCallback) {
        request(
          "get",
          URLS.INFO_CENTER_CATEGORIES + requestQuery(query),
          {},
          callback,
          errorCallback
        );
      },

      ADD: function (body, callback, errorCallback) {
        request("post", URLS.INFO_CENTER_CATEGORIES, body, callback, errorCallback);
      },

      EDIT: function (body, callback, errorCallback) {
        request("put", URLS.INFO_CENTER_CATEGORIES, body, callback, errorCallback);
      },

      DELETE: function (id, callback, errorCallback) {
        request("delete", URLS.INFO_CENTER_CATEGORIES, { id }, callback, errorCallback);
      },
      STATISTICS: function (callback, errorCallback) {
        request(
          "get",
          `${URLS.INFO_CENTER_CATEGORIES}/statistics`,
          {},
          callback,
          errorCallback
        );
      },
    },
  },
  // j
  // k
  // l

  LANGUAGES: {
    GET: function (query, callback, errorCallback) {
      request("get", URLS.LANGUAGES + requestQuery(query), {}, callback, errorCallback);
    },

    ADD: function (body, callback, errorCallback) {
      request("post", URLS.LANGUAGES, body, callback, errorCallback);
    },

    CHANGE: function (body, callback, errorCallback) {
      request("put", URLS.LANGUAGES, body, callback, errorCallback);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.LANGUAGES, { id }, callback, errorCallback);
    },
  },
  // m
  MONITORING: {
    ONLINE_DEVICES: function (query, callback, errorCallback) {
      request(
        "get",
        URLS.ONLINE_DEVICES + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },
  },

  MEDIA_LIBRARY: {
    GET: function (query, callback, errorCallback) {
      request("get", URLS.FILE + requestQuery(query), {}, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.FILE, body, callback, errorCallback);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.FILE, { id }, callback, errorCallback);
    },
    STATISTICS: function (callback, errorCallback) {
      request("get", `${URLS.FILE}/statistics`, {}, callback, errorCallback);
    },
  },
  // n
  // o
  ORGANIZATIONS: {
    GET: function (query, callback, errorCallback) {
      request(
        "get",
        URLS.ORGANIZATION + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },

    ADD: function (body, callback, errorCallback) {
      request("post", URLS.ORGANIZATION, body, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.ORGANIZATION, body, callback, errorCallback);
    },

    INFO: function (callback, errorCallback) {
      request("get", `${URLS.ORGANIZATION}/info`, {}, callback, errorCallback);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.ORGANIZATION, { id }, callback, errorCallback);
    },
  },

  ORDERS: {
    GET: function (query, callback, errorCallback) {
      request("get", URLS.ORDER + requestQuery(query), {}, callback, errorCallback);
    },
    ADD: function (body, callback, errorCallback) {
      request("post", URLS.ORDER, body, callback, errorCallback);
    },
    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.ORDER, body, callback, errorCallback);
    },
    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.ORDER, { id }, callback, errorCallback);
    },
    STATISTICS: function (callback, errorCallback) {
      request("get", `${URLS.ORDER}/statistics`, {}, callback, errorCallback);
    },
    COMPLETED: function (body, callback, errorCallback) {
      request("post", `${URLS.ORDER}/completed`, body, callback, errorCallback);
    },
  },
  // p
  PACKAGES: {
    GET: function (query, callback, errorCallback) {
      request("get", URLS.PACKAGES + requestQuery(query), {}, callback, errorCallback);
    },
    ADD: function (body, callback, errorCallback) {
      request("post", URLS.PACKAGES, body, callback, errorCallback);
    },
    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.PACKAGES, body, callback, errorCallback);
    },
    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.PACKAGES, { id }, callback, errorCallback);
    },

    DEFAULT: function (id, callback, errorCallback) {
      request("post", `${URLS.PACKAGES}/changeDefault`, { id }, callback, errorCallback);
    },
  },
  PROMOTIONS: {
    NEWS: {
      GET: function (query, callback, errorCallback) {
        request("get", URLS.NEWS + requestQuery(query), {}, callback, errorCallback);
      },
      ADD: function (body, callback, errorCallback) {
        request("post", URLS.NEWS, body, callback, errorCallback);
      },
      EDIT: function (body, callback, errorCallback) {
        request("put", URLS.NEWS, body, callback, errorCallback);
      },
      DELETE: function (id, callback, errorCallback) {
        request("delete", URLS.NEWS, { id }, callback, errorCallback);
      },
      STATISTICS: function (callback, errorCallback) {
        request("get", `${URLS.NEWS}/statistics`, {}, callback, errorCallback);
      },
    },

    CATEGORIES: {
      GET: function (query, callback, errorCallback) {
        request(
          "get",
          URLS.NEWS_CATEGORIES + requestQuery(query),
          {},
          callback,
          errorCallback
        );
      },

      ADD: function (body, callback, errorCallback) {
        request("post", URLS.NEWS_CATEGORIES, body, callback, errorCallback);
      },

      EDIT: function (body, callback, errorCallback) {
        request("put", URLS.NEWS_CATEGORIES, body, callback, errorCallback);
      },

      DELETE: function (id, callback, errorCallback) {
        request("delete", URLS.NEWS_CATEGORIES, { id }, callback, errorCallback);
      },
      STATISTICS: function (callback, errorCallback) {
        request("get", `${URLS.NEWS_CATEGORIES}/statistics`, {}, callback, errorCallback);
      },
    },
  },

  PMS_OPERA: {
    GET: function ({}, callback, errorCallback) {
      request("get", `${URLS.PMS_OPERA}/api`, {}, callback, errorCallback);
    },
    EDIT: function (body, callback, errorCallback) {
      request("put", `${URLS.PMS_OPERA}/api`, body, callback, errorCallback);
    },
  },
  // q
  QUICK_MESSAGES: {
    GET: function (query, callback, errorCallback) {
      request(
        "get",
        `${URLS.QUICK_MESSAGES}${requestQuery(query)}`,
        {},
        callback,
        errorCallback
      );
    },
    ADD: function (body, callback, errorCallback) {
      request("post", `${URLS.QUICK_MESSAGES}`, body, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.QUICK_MESSAGES, body, callback, errorCallback);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.QUICK_MESSAGES, { id }, callback, errorCallback);
    },

    CHAT_QUICK_MESSAGES: function (body, callback, errorCallback) {
      request("post", `${URLS.CHAT_QUICK_MESSAGES}`, body, callback, errorCallback);
    },
  },
  // r
  ROOMS: {
    GET: function (query, callback, errorCallback) {
      request("get", `${URLS.ROOMS}${requestQuery(query)}`, {}, callback, errorCallback);
    },

    // ADD: function (body, callback, errorCallback) {
    //     request("post", `${URLS.ROOMS}/addRooms`, body, callback, errorCallback);
    // },

    ADD: function (body, callback, errorCallback) {
      request("post", `${URLS.ROOMS}`, body, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.ROOMS, body, callback, errorCallback);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.ROOMS, { id }, callback, errorCallback);
    },
    STATISTICS: function (callback, errorCallback) {
      request("get", `${URLS.ROOMS}/statistics`, {}, callback, errorCallback);
    },

    CHANGE_GROUP: function (body, callback, errorCallback) {
      request("post", `${URLS.ROOMS}/groupChanged`, body, callback, errorCallback);
    },

    GROUPS: {
      GET: function (query, callback, errorCallback) {
        request(
          "get",
          `${URLS.ROOM_GROUPS}${requestQuery(query)}`,
          {},
          callback,
          errorCallback
        );
      },

      ADD: function (body, callback, errorCallback) {
        request("post", `${URLS.ROOM_GROUPS}`, body, callback, errorCallback);
      },

      EDIT: function (body, callback, errorCallback) {
        request("put", URLS.ROOM_GROUPS, body, callback, errorCallback);
      },
      DELETE: function (id, callback, errorCallback) {
        request("delete", URLS.ROOM_GROUPS, { id }, callback, errorCallback);
      },

      STATISTICS: function (callback, errorCallback) {
        request("get", `${URLS.ROOM_GROUPS}/statistics`, {}, callback, errorCallback);
      },
    },
    PACKAGES: {
      ADD: function (body, callback, errorCallback) {
        request("post", `${URLS.ROOMS}/groupPackage`, body, callback, errorCallback);
      },
    },

    DEVICES: {
      GET: function (query, callback, errorCallback) {
        request(
          "get",
          `${URLS.ROOM_DEVICES}${requestQuery(query)}`,
          {},
          callback,
          errorCallback
        );
      },

      ADD: function (body, callback, errorCallback) {
        request("post", URLS.ROOM_DEVICES, body, callback, errorCallback);
      },

      DELETE: function (id, callback, errorCallback) {
        request("delete", URLS.ROOM_DEVICES, { id }, callback, errorCallback);
      },

      EDIT: function (body, callback, errorCallback) {
        request("put", URLS.ROOM_DEVICES, body, callback, errorCallback);
      },

      CHANGE_PACKAGE: function (body, callback, errorCallback) {
        request(
          "post",
          `${URLS.ROOM_DEVICES}/packageChanged`,
          body,
          callback,
          errorCallback
        );
      },

      SEND_CODE: function (body, callback, errorCallback) {
        request("post", `${URLS.ROOM_DEVICES}/code`, body, callback, errorCallback);
      },
    },

    GUESTS_GROUPS: {
      GET: function (query, callback, errorCallback) {
        request(
          "get",
          `${URLS.GUESTS_GROUPS}${requestQuery(query)}`,
          {},
          callback,
          errorCallback
        );
      },

      ADD: function (body, callback, errorCallback) {
        request("post", `${URLS.GUESTS_GROUPS}`, body, callback, errorCallback);
      },

      EDIT: function (body, callback, errorCallback) {
        request("put", URLS.GUESTS_GROUPS, body, callback, errorCallback);
      },
      DELETE: function (id, callback, errorCallback) {
        request("delete", URLS.GUESTS_GROUPS, { id }, callback, errorCallback);
      },

      STATISTICS: function (callback, errorCallback) {
        request("get", `${URLS.GUESTS_GROUPS}/statistics`, {}, callback, errorCallback);
      },
    },
  },

  // s
  SCREEN_MIRRORING: {
    GET: function (query, callback, errorCallback) {
      request(
        "get",
        `${URLS.SCREEN_MIRRORING}${requestQuery(query)}`,
        {},
        callback,
        errorCallback
      );
    },

    ADD: function (body, callback, errorCallback) {
      request("post", `${URLS.SCREEN_MIRRORING}`, body, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.SCREEN_MIRRORING, body, callback, errorCallback);
    },
    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.SCREEN_MIRRORING, { id }, callback, errorCallback);
    },
  },
  // t

  TV_APPLICATION: {
    GET: (callback, errorCallback) => {
      request("get", URLS.TV_MENU, {}, callback, errorCallback);
    },

    TV_MENU_CHANGE: function (body, callback, errorCallback) {
      request("put", URLS.TV_MENU_CHANGE, body, callback, errorCallback);
    },

    TV_MENU_POSITION: function (body, callback, errorCallback) {
      request("put", URLS.TV_MENU_POSITION, body, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.TV_MENU, body, callback, errorCallback);
    },

    ENABLE_OR_DESABLE: (body, callback, errorCallback) => {
      request("put", URLS.TV_MENU, body, callback, errorCallback);
    },

    CONFIG: {
      GET: (callback, errorCallback) => {
        request("get", URLS.TV_APP_CONFIG, {}, callback, errorCallback);
      },

      EDIT: (formData, callback, errorCallback) => {
        request("put", URLS.TV_APP_CONFIG, formData, callback, errorCallback);
      },
    },
  },

  TV_CHANNELS: {
    GET: function (query, callback, errorCallback) {
      request("get", URLS.TV_CHANNELS + requestQuery(query), {}, callback, errorCallback);
    },

    ADD: function (body, callback, errorCallback) {
      request("post", URLS.TV_CHANNELS, body, callback, errorCallback);
    },

    ADD_FILE: function (body, callback, errorCallback) {
      request("post", `${URLS.TV_CHANNELS}/add_m3u`, body, callback, errorCallback, true);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.TV_CHANNELS, body, callback, errorCallback);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.TV_CHANNELS, { id }, callback, errorCallback);
    },

    STATISTICS: function (callback, errorCallback) {
      request("get", `${URLS.TV_CHANNELS}/statistics`, {}, callback, errorCallback);
    },

    CHECK_NUMBER: function (body, callback, errorCallback) {
      request("post", `${URLS.TV_CHANNELS}/check_number`, body, callback, errorCallback);
    },
  },
  TV_CHANNELS_CATEGORIES: {
    GET: function (query, callback, errorCallback) {
      request(
        "get",
        URLS.TV_CHANNELS_CATEGORIES + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },
    ADD: function (body, callback, errorCallback) {
      request("post", URLS.TV_CHANNELS_CATEGORIES, body, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.TV_CHANNELS_CATEGORIES, body, callback, errorCallback);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.TV_CHANNELS_CATEGORIES, { id }, callback, errorCallback);
    },
    STATISTICS: function (callback, errorCallback) {
      request(
        "get",
        `${URLS.TV_CHANNELS_CATEGORIES}/statistics`,
        {},
        callback,
        errorCallback
      );
    },
  },

  TV_CHANNELS_SETTINGS: {
    GET: function (callback, errorCallback) {
      request("get", URLS.TV_CHANNELS_SETTINGS, {}, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.TV_CHANNELS_SETTINGS, body, callback, errorCallback);
    },
  },
  TRANSLATIONS: {
    GET: function (query, callback, errorCallback) {
      request(
        "get",
        URLS.TRANSLATIONS + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },

    ADD: function (body, callback, errorCallback) {
      request("post", URLS.TRANSLATIONS, body, callback, errorCallback);
    },

    UPDATE_KEY: function (body, callback, errorCallback) {
      request("post", `${URLS.TRANSLATIONS}/update_key`, body, callback, errorCallback);
    },

    UPDATE_VALUE: function (body, callback, errorCallback) {
      request("post", `${URLS.TRANSLATIONS}/update_value`, body, callback, errorCallback);
    },

    DELETE: function (key, callback, errorCallback) {
      request("delete", `${URLS.TRANSLATIONS}/remove`, { key }, callback, errorCallback);
    },
  },
  // u
  // v
  VOD: {
    GENRES: {
      GET: function (query, callback, errorCallback) {
        request("get", URLS.GENRES + requestQuery(query), {}, callback, errorCallback);
      },

      ADD: function (body, callback, errorCallback) {
        request("post", URLS.GENRES, body, callback, errorCallback);
      },

      EDIT: function (body, callback, errorCallback) {
        request("put", URLS.GENRES, body, callback, errorCallback);
      },

      DELETE: function (id, callback, errorCallback) {
        request("delete", URLS.GENRES, { id }, callback, errorCallback);
      },

      STATISTICS: function (callback, errorCallback) {
        request("get", `${URLS.GENRES}/statistics`, {}, callback, errorCallback);
      },
    },

    MOVIES: {
      GET: function (query, callback, errorCallback) {
        request("get", URLS.VOD + requestQuery(query), {}, callback, errorCallback);
      },

      ADD: function (body, callback, errorCallback) {
        request("post", URLS.VOD, body, callback, errorCallback);
      },

      EDIT: function (body, callback, errorCallback) {
        request("put", URLS.VOD, body, callback, errorCallback);
      },

      DELETE: function (id, callback, errorCallback) {
        request("delete", URLS.VOD, { id }, callback, errorCallback);
      },

      STATISTICS: function (callback, errorCallback) {
        request("get", `${URLS.VOD}/statistics`, {}, callback, errorCallback);
      },

      ADD_FROM_TMDB: (body, callback, errorCallback) => {
        request("post", `${URLS.VOD}/add_tmdb_id`, body, callback, errorCallback, true);
      },

      UPLOAD1: {
        STATUS: (query, callback, errorCallback) => {
          request(
            "get",
            URLS.UPLOAD_STATUS + requestQuery(query),
            {},
            callback,
            errorCallback
          );
        },
      },

      UPLOAD: {
        STATUS: (body, callback, errorCallback) => {
          request("post", URLS.UPLOAD_STATUS, body, callback, errorCallback);
        },
      },

      TV_SHOWS: {
        SEASONS: {
          GET: function (query, callback, errorCallback) {
            request(
              "get",
              // `${URLS.TV_SHOW_SEASONS}?filter=vod_id: "`
              URLS.TV_SHOW_SEASONS + requestQuery(query),
              {},
              callback,
              errorCallback
            );
          },

          ADD: function (body, callback, errorCallback) {
            request("post", URLS.TV_SHOW_SEASONS, body, callback, errorCallback);
          },

          EDIT: function (body, callback, errorCallback) {
            request("put", URLS.TV_SHOW_SEASONS, body, callback, errorCallback);
          },

          DELETE: function (id, callback, errorCallback) {
            request("delete", URLS.TV_SHOW_SEASONS, { id }, callback, errorCallback);
          },

          // /tv_show_seasons -> vod_id
          // /tv_show_episodes season_id
        },

        EPISODES: {
          GET: function (query, callback, errorCallback) {
            request(
              "get",
              URLS.TV_SHOW_EPISODES + requestQuery(query),
              {},
              callback,
              errorCallback
            );
          },

          ADD: function (body, callback, errorCallback) {
            request("post", URLS.TV_SHOW_EPISODES, body, callback, errorCallback);
          },

          EDIT: function (body, callback, errorCallback) {
            request("put", URLS.TV_SHOW_EPISODES, body, callback, errorCallback);
          },

          DELETE: function (id, callback, errorCallback) {
            request("delete", URLS.TV_SHOW_EPISODES, { id }, callback, errorCallback);
          },
        },
      },
    },
  },
  HELP_INFO: {
    GET: function (query, callback, errorCallback) {
      request("get", URLS.HELP_INFO + requestQuery(query), {}, callback, errorCallback);
    },

    ADD: function (body, callback, errorCallback) {
      request("post", URLS.HELP_INFO, body, callback, errorCallback);
    },

    EDIT: function (body, callback, errorCallback) {
      request("put", URLS.HELP_INFO, body, callback, errorCallback);
    },

    INFO: function (callback, errorCallback) {
      request("get", `${URLS.HELP_INFO}/info`, {}, callback, errorCallback);
    },

    DELETE: function (id, callback, errorCallback) {
      request("delete", URLS.HELP_INFO, { id }, callback, errorCallback);
    },
  },
  // w
  // x
  // y
  // z
};

export default REQUESTS;
