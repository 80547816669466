const dashboard = "/dashboard";

const NAVIGATORS = {
  // a
  APPLICATION: `${dashboard}/application`,
  // b
  // c
  CHECK_EMAIL: "/check-email",
  // d
  DASHBOARD: dashboard,
  DASHBOARD_CONTENT: `${dashboard}/content`,

  // e
  ECOMMERCE_PRODUCTS: `${dashboard}/ecommerce-products`,
  ECOMMERCE_CATEGORIES: `${dashboard}/ecommerce-categories`,
  ECOMMERCE_SERVICES: `${dashboard}/ecommerce-services`,

  // f
  // g
  // h
  HOST_GROUPS: `${dashboard}/host-groups`,
  HELP_INFO: `${dashboard}/help-info`,

  // i
  IT: `${dashboard}/it`,
  INFO_CENTER: `${dashboard}/info-center`,
  INFO_CENTER_ORGANIZATION_INFO: `${dashboard}/info-center-organization-info`,
  INFO_CENTER_LOCAL_INFO: `${dashboard}/info-center-local-info`,
  INFO_CENTER_USEFUL_INFO: `${dashboard}/info-center-useful-info`,
  INFO_CENTER_CATEGORIES: `${dashboard}/info-center-categories`,

  // j
  // k
  // l
  LOGIN: "/login",
  LIVE_TV_CATEGORIES: `${dashboard}/live-tv-categories`,
  LIVE_TV_GENERALS: `${dashboard}/live-tv-generals`,
  LIVE_TV_EPG_LINKS: `${dashboard}/live-tv-epg-links`,
  LIVE_TV_TVCHANNELS: `${dashboard}/live-tv-channels`,
  LOCATIONS: `${dashboard}/locations`,
  LOCATION: `${dashboard}/location`,

  // m
  MEDIA: `${dashboard}/media`,
  MEDIA_VOD: `${dashboard}/media-vod`,
  MEDIA_VOD_GENRES: `${dashboard}/media-vod-genres`,
  MEDIA_VOD_MOVES: `${dashboard}/media-vod-moves`,
  MEDIA_LIBRARY: `${dashboard}/media-library`,
  MESSAGES: `${dashboard}/messages`,
  MONITORING: `${dashboard}/monitoring`,
  // n
  NEWS: `${dashboard}/news`,
  // o
  ORDERS: `${dashboard}/orders`,
  // ORDERS_PRODUCTS: `${dashboard}/orders-products`,
  // ORDERS_SERVICES: `${dashboard}/orders-services`,
  ORGANIZATION_CATEGORIES: `${dashboard}/organization-categories`,
  ORGANIZATION_COMPANIES: `${dashboard}/organization-companies`,

  // p

  PACKAGES: `${dashboard}/packages`,
  PRODUCTS: `${dashboard}/products`,
  PROMOTIONS_NEWS: `${dashboard}/promotions-news`,
  PROMOTIONS_CATEGORIES: `${dashboard}/promotions-categories`,

  // q
  // r
  ROOMS: `${dashboard}/rooms`,
  RESET_PASSWORD: "/reset-password",
  ROOM: `${dashboard}/room`,

  // s
  SERVICES_PRODUCTS: `${dashboard}/services-products`,
  SERVICES_CATEGORIES: `${dashboard}/services-categories`,
  SETTINGS: `${dashboard}/settings`,
  SCREEN_MIRRORING: `${dashboard}/screen-mirroring`,
  // t
  TV_APPLICATION: `${dashboard}/tv-application`,
  TRANSLATION: `${dashboard}/translation`,
  // u
  // v
  // w
  // x
  // y
  // z
};

export default NAVIGATORS;
