import React, { useEffect, useState } from "react";

import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../store";

import REQUESTS from "../../server/requests";

import { Menu, Dropdown, Space } from "antd";

export default function LanguageBlock() {
    const dispatch = useDispatch();

    const {languages, activeLanguages, getTranslation} = useSelector((state) => state.globalState);

    const { setLanguages, setTranslate, setActiveLanguages } = bindActionCreators(actions, dispatch);

    const [defaultLanguage, setDefaultLanguage] = useState({});

    const selectDefault = (item) => {
        if(item?.iso_code === defaultLanguage?.iso_code) return;

        const body = {
            id: item.id,
            is_default: true,
        };

        function callback() {
            getLanguages();

            window.location.reload();
        }
        function errorCallback(err) {}

        

        REQUESTS.LANGUAGES.CHANGE(body, callback, errorCallback);
    };

    const getLanguages = () => {
        REQUESTS.LANGUAGES.GET({}, (data) => {
            setLanguages(data);
        });
    };

    const getTranslations = () => {
        if (defaultLanguage.id) {
            const query = {
                languages_id: defaultLanguage.id,
            };

            REQUESTS.TRANSLATIONS.GET(query, (data) => {
                setTranslate(data.translations || {});
            });
        }
    };

    useEffect(() => {
        getTranslations();
    }, [defaultLanguage, getTranslation]);

    useEffect(() => {
        const list = languages.filter((item) => item.is_active);
        const isDefault = languages.find((item) => item.is_default);

        setActiveLanguages(list);
        setDefaultLanguage(isDefault || {});
    }, [languages]);



    useEffect(() => {
        getLanguages();
    }, []);


    const menu = (
        <div className="wrap-leng-menu">
            <Menu>
                {activeLanguages?.map((item) => {
                    return (
                        <Menu.Item key={item.id} title={item.name}>
                            <div className="df-list-lng" onClick={() => selectDefault(item)}>
                                <img src={item.icon} width={20} height={15} />
                                <span>{item.name}</span>
                            </div>
                        </Menu.Item>
                    );
                })}
            </Menu>
        </div>
    );

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <Space style={{ color: "#fff", cursor: "pointer" }}>
                <img src={defaultLanguage.icon} width={20} height={15} />
                <span style={{ textTransform: "uppercase" }}>{defaultLanguage.code}</span>
            </Space>
        </Dropdown>
    );
}
