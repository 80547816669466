import React, { useEffect, useState } from "react";

import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";

import { actions } from "../../../store";

import EN from "../../../config/en";
import IMAGES from "../../../config/images";
import DATE_FORMATER from "../../../config/dateFormat";

import REQUESTS from "../../../server/requests";
import WEATHER_API from "../../../server/weatherapi";

import Page from "../../elements/Page";
import TitleComponent from "../../elements/TitleComponent";
import AddButtonComponent from "../../elements/AddButtonComponent";
import ReachFormatTextComponent from "../../elements/ReachFormatTextComponent";

import MenuItem from "./MenuItem";
import ItemsDrawer from "./ItemsDrawer";
import WetherDrawer from "./WetherDrawer";
import AddConfigButton from "./AddConfigButton";
import EditConfigButton from "./EditConfigButton";
import DateFormatDrawer from "./DateFormatDrawer";
import WelcomeMessageDrawer from "./WelcomeMessageDrawer";
import LogoBackgroundDrawer from "./LogoBackgroundDrawer";
import GeneralPreferencesDrawer from "./GeneralPreferencesDrawe";

import { SortablePane, Pane } from "react-sortable-pane";

import "./_tv-application.scss";
import { notification } from "antd";

let timeout = null;

export default function TVApplicationPage() {
    const dispatch = useDispatch();

    const translate = useSelector((state) => state.globalState.translate);

    const info = useSelector((state) => state.globalState.organizationInfo);

    const { setOrganizationInfoInRedux } = bindActionCreators(actions, dispatch);

    const [menuList, setMenuList] = useState([]);

    const [visibleGeneralPreferencesDrawer, setVisibleGeneralPreferencesDrawer] = useState(false);

    const [visiblWether, setVisiblWether] = useState(false);
    const [visibleWelcomeMessageDrawer, setVisibleWelcomeMessageDrawer] = useState(false);
    const [visiblImages, setVisiblImages] = useState(false);
    const [visibleDateFormatDrawer, setVisibleDateFormatDrawer] = useState(false);
    const [visiblItems, setVisiblItems] = useState(false);

    const [currentItems, setCurrentItems] = useState(null);
    const [inActiveItem, setInActiveItem] = useState(true);

    const [weather, setWeather] = useState({});
    const [currentDate, setCurrentDate] = useState(null);

    const [tvApplicationConfig, setCurrentConfig] = useState({
        items: null,
    });

    useEffect(() => {
        REQUESTS.ORGANIZATIONS.INFO((data) => {
            setOrganizationInfoInRedux(data[0]);
        });
    }, []);

    useEffect(() => {
        getWeather();
    }, [info]);

    useEffect(() => {
        getFormatDate();

        const interval = setInterval(() => {
            getFormatDate();
        }, [60000]);

        return () => {
            clearInterval(interval);
        };
    }, [tvApplicationConfig]);

    const [background, setBackground] = useState({
        url: null,
        file: null,
        backgroundId: null,
    });

    const [logo, setLogo] = useState({
        url: null,
        file: null,
    });

    useEffect(() => {
        getConfig();
    }, [visiblImages]);

    useEffect(() => {
        getMenuList();
    }, [currentItems, inActiveItem]);

    const getMenuList = () => {
        REQUESTS.TV_APPLICATION.GET((data) => {
            setMenuList(data);
        });
    };

    const getCity = async () => {
        let last_update_date = localStorage.getItem("last_updted_date");
        let current_time = parseInt(new Date().getTime(), 10);

        if (!last_update_date || current_time > parseInt(last_update_date, 10)) {
            try {
                let city = info?.city;
                localStorage.setItem("city", city);
                localStorage.setItem(
                    "last_updted_date",
                    new Date().getDate(new Date().getDate() + 1)
                );

                return city;
            } catch (e) {
                return localStorage.getItem("city") || "Moscow";
            }
        } else {
            return localStorage.getItem("city") || "Moscow";
        }
    };

    const getWeather = async () => {
        WEATHER_API.GET(
            await getCity(),
            (data) => {
                setWeather(data);
            },
            {}
        );
    };

    const getConfig = () => {
        REQUESTS.TV_APPLICATION.CONFIG.GET((data) => {
            setCurrentConfig({
                items: data[0],
            });
            setBackground({
                url: data[0].background_image,
                file: null,
            });

            setLogo({
                url: data[0].logo,
                file: null,
            });
        });
    };

    const onItem = (item) => {
        setCurrentItems(item);
        setVisiblItems(true);
    };

    const getFormatDate = () => {
        if (tvApplicationConfig.items && tvApplicationConfig.items.date_format) {
            let newDate = DATE_FORMATER.parse(
                tvApplicationConfig.items.date_hour_format,
                tvApplicationConfig.items.date_format
            );

            setCurrentDate(newDate);
        }
    };

    const onOrderChange = (list) => {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            changePositions(list);
        }, 2000);
    };

    const changePositions = (positions) => {
        let list = positions.map((item, index) => ({
            id: item,
            position: index + 1,
        }));

        REQUESTS.TV_APPLICATION.TV_MENU_POSITION(
            {
                update_data: JSON.stringify(list),
            },
            (data) => {
                notification.success({
                    description: data,
                });
            }
        );
    };

    return (
        <Page>
            <div className="page-body">
                <div className="tv-app-header">
                    <TitleComponent>{translate['Tv Application'] || EN['Tv Application']}</TitleComponent>

                    <AddButtonComponent
                        style={{ width: 210 }}
                        text={translate['General preferences'] || EN['General preferences']}
                        onClick={() => setVisibleGeneralPreferencesDrawer(true)}
                    />
                </div>

                <div className="tv-page">
                    <div className="tv-style">
                        <img
                            src={background.url ? background.url : IMAGES.BLACK}
                            className="tv-bg"
                            alt="tv"
                        />

                        <div className="tv-body">
                            <div className="header-content">
                                {tvApplicationConfig.items?.display_weather ? (
                                    <EditConfigButton onClick={() => setVisiblWether(true)}>
                                        <div className="weather-block">
                                            {weather && (
                                                <>
                                                    <div style={{ display: "flex" }}>
                                                        <p>
                                                            {weather?.current?.feelslike_c &&
                                                                parseInt(
                                                                    weather?.current?.feelslike_c
                                                                )}
                                                            <span className="o">o</span>
                                                            <span className="c">C</span>
                                                        </p>
                                                        {tvApplicationConfig.items
                                                            .weather_picture && (
                                                            <img
                                                                src={
                                                                    weather?.current?.condition
                                                                        ?.icon
                                                                }
                                                                width="30"
                                                                height="30"
                                                                alt="weather"
                                                            />
                                                        )}
                                                    </div>

                                                    {tvApplicationConfig.items.display_city && (
                                                        <span style={{ fontSize: 13 }}>
                                                            {weather?.location?.name}
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </EditConfigButton>
                                ) : (
                                    <AddConfigButton
                                        text={translate['Weather forecast'] || EN['Weather forecast']}
                                        onClick={() => setVisiblWether(true)}
                                    />
                                )}

                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {tvApplicationConfig.items?.show_logo && logo.url ? (
                                        <EditConfigButton onClick={() => setVisiblImages(true)}>
                                            <img
                                                src={logo.url}
                                                alt="your logo"
                                                style={{ width: 120 }}
                                            />
                                        </EditConfigButton>
                                    ) : (
                                        <AddConfigButton
                                            text={
                                                translate['Logotype & Branding'] || EN['Logotype & Branding']
                                            }
                                            onClick={() => setVisiblImages(true)}
                                        />
                                    )}
                                </div>

                                <div className="date-block">
                                    {tvApplicationConfig.items?.display_date ? (
                                        <EditConfigButton
                                            className={"date-edit-config-button"}
                                            onClick={() => setVisibleDateFormatDrawer(true)}
                                        >
                                            <span
                                                style={{
                                                    fontSize: 16,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {currentDate}
                                            </span>
                                        </EditConfigButton>
                                    ) : (
                                        <AddConfigButton
                                            text={translate['Date & time'] || EN['Date & time']}
                                            onClick={() => setVisibleDateFormatDrawer(true)}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="body">
                                <div style={{ width: 320 }}>
                                    {tvApplicationConfig.items?.show_welcome_message ? (
                                        <EditConfigButton
                                            onClick={() => setVisibleWelcomeMessageDrawer(true)}
                                        >
                                            <ReachFormatTextComponent className="welcome-message">
                                                {tvApplicationConfig.items?.welcome_message}
                                            </ReachFormatTextComponent>
                                        </EditConfigButton>
                                    ) : (
                                        <AddConfigButton
                                            text={translate['Welcome message'] || EN['Welcome message']}
                                            onClick={() => setVisibleWelcomeMessageDrawer(true)}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="footer">
                                <SortablePane
                                    direction="horizontal"
                                    margin={5}
                                    onOrderChange={onOrderChange}
                                >
                                    {menuList.map((item) => {
                                        return (
                                            <Pane id={item.id} key={item.id} width={120}>
                                                <EditConfigButton
                                                    key={item.id}
                                                    onlyButton={true}
                                                    onClick={() => onItem(item)}
                                                    className="tv-config-menu-item"
                                                >
                                                    <MenuItem item={item} />
                                                </EditConfigButton>
                                            </Pane>
                                        );
                                    })}
                                </SortablePane>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <WetherDrawer
                onClose={() => setVisiblWether(false)}
                visible={visiblWether}
                tvApplicationConfig={tvApplicationConfig}
                getConfig={getConfig}
            />

            <WelcomeMessageDrawer
                getConfig={getConfig}
                config={tvApplicationConfig}
                visible={visibleWelcomeMessageDrawer}
                onClose={() => setVisibleWelcomeMessageDrawer(false)}
            />

            <LogoBackgroundDrawer
                onClose={() => setVisiblImages(false)}
                visible={visiblImages}
                tvApplicationConfig={tvApplicationConfig}
                getConfig={getConfig}
            />

            <DateFormatDrawer
                getConfig={getConfig}
                config={tvApplicationConfig}
                visible={visibleDateFormatDrawer}
                onClose={() => setVisibleDateFormatDrawer(false)}
            />

            <ItemsDrawer
                onClose={() => setVisiblItems(false)}
                visible={visiblItems}
                currentItems={currentItems}
                getData={getMenuList}
            />

            <GeneralPreferencesDrawer
                getConfig={getConfig}
                tvApplicationConfig={tvApplicationConfig}
                visible={visibleGeneralPreferencesDrawer}
                onClose={() => setVisibleGeneralPreferencesDrawer(false)}
            />
        </Page>
    );
}
