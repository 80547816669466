import React, { useState } from "react";
import REQUESTS from "../../../server/requests";

import TableHeadComponent from "../../elements/TableHeadComponent";
import CsvLinkComponent from "../../elements/CsvLinkComponent";


export default function GuiTableHead() {

    const [clickedCsv, setClickedCsv] = useState(false);
    const [dataList, setDataList] = useState([]);

    const onClicCsv = (event, done) => {
        if (!clickedCsv) {
            setClickedCsv(true);

            function callback(data) {
                const newList = data.map((list) => {
                    return {
                        Image: list.image,
                        Title: list.title,
                        Data: list.createdAt,
                    };
                });

                setDataList(newList);

                setClickedCsv(false);
            }

            function errorCallback() {
                setClickedCsv(false);
            }

            REQUESTS.GUI.GET({}, callback, errorCallback);
        }
    };
    return (
        <TableHeadComponent>
            <CsvLinkComponent
                filename="Gui-List.csv"
                data={dataList}
                onClick={onClicCsv}
            />
        </TableHeadComponent>
    );
}
