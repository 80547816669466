import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, Form, Input, Select } from "antd";

import REQUESTS from "../../../../server/requests";

import ErrorMessage from "../../../elements/ErrorMessage";
import SaveButtonComponent from "../../../elements/SaveButtonComponent";

import EN from "../../../../config/en";
import ICONS from "../../../../config/icons";

import {
  useProductsOptions,
  useServicesOptions,
  useVodOptions,
  useOrdersOptions,
} from "../../../../hooks/options";

export default function AddOrdersDrawer({
  visible,
  onClose,
  current,
  getData,
}) {
  const translate = useSelector((state) => state.globalState.translate);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const productsOptions = useProductsOptions();
  const servicesOptions = useServicesOptions();
  const vodOptions = useVodOptions();
  const ordersTypesOptions = useOrdersOptions();

  const [selectedOrdersType, setSelectedOrdersType] = useState("product");

  useEffect(() => {
    if (visible) {
      form.resetFields();
    } else {
      setSelectedOrdersType("product");
      setErrorText(null);
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);
    setErrorText(null);

    let bodyProduct = {
      quantity: parseFloat(values.quantity),
      room_id: current.id,
      type: values.type,
      row_id: values.product_id,
    };

    let bodyServices = {
      room_id: current.id,
      type: values.type,
      row_id: values.service_id,
    };

    let bodyVod = {
      room_id: current.id,
      type: values.type,
      row_id: values.vod_id,
    };

    function callback() {
      onClose();
      setLoading(false);

      if (getData) getData();
    }

    function errorCallback(err) {
      setErrorText(err);
      setLoading(false);
    }
    if (values.type === "product" && values.product_id) {
      REQUESTS.ORDERS.ADD(bodyProduct, callback, errorCallback);
    }
    if (values.type === "service" && values.service_id) {
      REQUESTS.ORDERS.ADD(bodyServices, callback, errorCallback);
    }
    if (values.type === "vod" && values.vod_id) {
      REQUESTS.ORDERS.ADD(bodyVod, callback, errorCallback);
    }
  };

  const onFinishFailed = () => {
    setErrorText(null);
  };

  return (
    <Drawer
      title={translate["Add New Orders"] || EN["Add New Orders"]}
      placement="right"
      width={400}
      visible={visible}
      onClose={onClose}
    >
      <Form
        form={form}
        layout="vertical"
        name="orders-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          type: selectedOrdersType,
        }}
      >
        <Form.Item
          label={
            translate["Select orders parametrs"] ||
            EN["Select orders parametrs"]
          }
          name="type"
          rules={[
            {
              required: true,
              message: `${
                translate["Select orders parametrs"] ||
                EN["Select orders parametrs"]
              }`,
            },
          ]}
        >
          <Select
            showArrow
            allowClear={true}
            value={selectedOrdersType}
            onChange={setSelectedOrdersType}
            options={ordersTypesOptions}
          />
        </Form.Item>

        {selectedOrdersType === "product" && (
          <Form.Item
            label={translate["Quantity"] || EN["Quantity"]}
            tooltip={{
              title: "Products quantity",
              icon: ICONS.INFO,
            }}
            name="quantity"
            rules={[
              {
                required: true,
                message: `${translate["Please input"] || EN["Please input"]} ${
                  translate["Title"] || EN["Title"]
                }`,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        )}
        <Form.Item
          label={
            selectedOrdersType === "product"
              ? translate["Products"] || EN["Products"]
              : selectedOrdersType === "service"
              ? translate["Services"] || EN["Services"]
              : translate["Vod"] || EN["Vod"]
          }
          tooltip={{
            title:
              selectedOrdersType === "product"
                ? "Choose product"
                : selectedOrdersType === "service"
                ? "Choose services"
                : "Choose vod",
            icon: ICONS.INFO,
          }}
          name={
            selectedOrdersType === "product"
              ? "product_id"
              : selectedOrdersType === "service"
              ? "service_id"
              : "vod_id"
          }
          rules={[
            {
              required: true,
              message: `${translate["Please select"] || EN["Please select"]} ${
                selectedOrdersType === "product"
                  ? translate["Products"] || EN["Products"]
                  : selectedOrdersType === "service"
                  ? translate["Services"] || EN["Services"]
                  : translate["Vod"] || EN["Vod"]
              }`,
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            showArrow
            allowClear={true}
            options={
              selectedOrdersType === "product"
                ? productsOptions
                : selectedOrdersType === "service"
                ? servicesOptions
                : vodOptions
            }
          />
        </Form.Item>

        <Form.Item>
          <SaveButtonComponent
            loading={loading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
      <ErrorMessage>{errorText}</ErrorMessage>
    </Drawer>
  );
}
